<template>
    <div>
        <Table
            :uid="table_uid"
            :ref="table_uid"
            :is_loading="is_loading"
            :columns="columns"
            :data="reminder_schedules"
            prevent_first_row_select
            :pager_config=5
            hide_action_buttons
            :button_methods="button_methods"
        >
            <template #title>
                Added Reminder Schedules
            </template>
            <template #buttons>
                <b-button variant="primary" size="sm" @click="$emit('newReminder')">
                    Create New Reminder
                </b-button>
            </template>
        </Table>
    </div>
    <!--
        :button_methods="button_methods"
        @selectedRowEvent="selectedRowEvent"
        @updateViewMode="updateViewMode"
        @saveTable="saveTable"
        @deleteRows="deleteRow"
    -->
</template>

<script>

import Table from '@/components/widgets/table/table.vue'
import { meetingState, adminComputed } from '@/state/helpers'
import _ from 'lodash'
import DateMixin from '@/mixins/dateMixin.js';
export default {
    components: { Table },
    mixins: [DateMixin],
    data:() => ({
        table_uid: "remindersTable",
        is_loading: false,
    }),
    computed:{
        ...meetingState,
        ...adminComputed,
        columns(){
            //let el = this;
            return [
                { id: 'type_name', header: 'Type', fillspace: 1},
                { id: 'date_range', header: 'Type', fillspace: 0.8},
                { id: 'actions', header: 'Actions', fillspace: 0.3,
                    template(obj){
                        if(obj.is_empty){ return '' }
                        else{
                            return `<span>
                                <i class="edit_action fas fa-pen ps-2 pe-2"></i>
                                <i class="delete_action fas fa-trash ps-2 pe-2"></i>
                            </span>`
                        }
                    }
                },
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action':function(e, id){
                    let reminder = el.meeting_data.reminders.find(item => item.id == id);
                    el.$emit('editReminder', reminder);
                },
                //eslint-disable-next-line
                'delete_action':function(e, id){
                    el.deleteItem(id)
                },
            }
            return onClick;
        },
        reminder_schedules(){
            if(this.meeting_data.reminders){
                let data = _.cloneDeep(this.meeting_data.reminders);
                data.forEach( item => {
                    item['type_name'] = this.getEmailTemplateForID(item.client_email_template_id);
                    item['date_range'] = this.getSimpleDate(item.start_date) + ' -> ' + this.getSimpleDate(item.end_date);
                })
                return data;
            }
            return [];
        }
    },
    methods:{
        getEmailTemplateForID(id){
            let item = this.reminder_email_templates.find( element => element.id == id);
            if(item){
                return item.name
            }
            else{
                return 'n/a'
            }
        },
        deleteItem(id){
            this.$emit('deleteItem', id)
        }
    },
    mounted(){},
}
</script>

<style>

</style>