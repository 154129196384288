<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { menuMethods } from "@/state/helpers";

import Meetings from "@/components/meetings/meetings.vue"
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Meetings",
    },
    components: {
        Layout,
        //eslint-disable-next-line
        PageHeader,
        Meetings,
    },
    data() {
        return {
            title: "Documents",
            items: [
                /*{
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Activity",
                    active: true,
                },*/
            ],
        };
    },
    methods:{
        ...menuMethods,
    },
        mounted() {
            setTimeout(()=>{
            this.setLoading(false);
            }, 500)
        },
};
</script>

<template>
    <Layout>
        <!--<PageHeader :title="title" :items="items" /> -->
        <Meetings />
    </Layout>
</template>
