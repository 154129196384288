<template>
    <b-modal title="Activity Linking" id="activityLinkingModal" hide-footer>
        <b-overlay :show="!hasMounted">
            <webix-ui  style="height: 100%;" v-model="available_plans" :config="ui" ></webix-ui>
            <div class="d-flex">
                <b-button @click="saveSelection" class="ms-auto mt-2" variant="success">Save</b-button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import _ from 'lodash'
import { meetingMethods, meetingState, levelComputed, } from '@/state/helpers'
export default {
    data:() => ({
        hasMounted: false,
        available_plans: [],
    }),
    watch:{
        hasMounted(){
            setTimeout(()=>{
                this.enableSelectedItems();
            }, 500)
        }
    },
    computed:{
        ...meetingState, ...levelComputed,
        ui(){
            return{
                id: 'linkingActvityTree',
                view: 'tree',
                css: '',
                drag: false,
                autoheight: true,
                tooltip: true,
                minHeight: 300,
                data: this.available_plans,
                template: "{common.icon()}{common.checkbox()} #name#",
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                    window.linking_modal_scope.hasMounted = true;
                },
            }
        }
    },
    methods:{
        ...meetingMethods,
        saveSelection(){
            let checked_items = window.webix.$$('linkingActvityTree').getChecked();
            let params = {
                id: this.selected_meeting_id,
                meeting_actions: checked_items,
            }
            this.hasMounted = false;
            this.linkActionsToMeetings(params)
            .then(()=>{
                this.$emit('saved');
            })
        },
        enableSelectedItems(){
            let tree = window.webix.$$('linkingActvityTree');
            this.meeting_activity_plans.forEach( plan =>{
                if(plan.linked_to_meeting == true && tree.exists(plan.id)){
                    let current_item = tree.getItem(plan.id);
                    while(current_item.$parent != 0){
                        tree.open(current_item.$parent);
                        current_item = tree.getItem(current_item.$parent);
                    }
                    tree.checkItem(plan.id);
                }
            })
        },
        processTree(){
            let data = _.cloneDeep(this.meeting_activity_plans);
            //data = _.orderBy(data, ['item_order']);
            data.forEach((element) => {
                element["parent"] = element.parent_id;
                element["value"] = element.name; //used for webix related stuff
                element['data'] = []; // array of children for the node
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id == null) {
                    //* Parent item -- do nothing
                } 
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;

                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }

                        }
                    });
                }
            });
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            //*Set parent root as expanded;
            /*if(this.data.length > 0){
                data[0]["opened"] = true;
                data[0]["open"] = true;
            }*/
            this.available_plans = data;
            this.hasMounted = true;
        },
    },
    mounted(){
        window.linking_modal_scope = this;
        this.processTree();
    },
    beforeDestroy(){
        window.linking_modal_scope = undefined;
    }
}
</script>

<style>

</style>