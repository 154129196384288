<template>
    <div>
        <b-card class="card-hover">
            <Table
                v-if="show_notes == false"
                :uid="table_uid"
                :ref="table_uid"
                :is_loading="is_loaidng_meetings || is_loading"
                :columns="columns"
                :data="table_meeting_data"
                :active_id="selected_meeting_id"
                :button_methods="button_methods"
                :default_values="default_values"
                :validation="table_validation_rules"
                @selectedRowEvent="selectedRowEvent"
                @updateViewMode="updateViewMode"
                @saveTable="saveTable"
                @deleteRows="deleteRow"
            >
                <template #title>
                    Meetings
                </template>
                <template #buttons>
                    <!--<span class="form-check form-switch pt-1 me-3 ms-2" style="font-size: 10px;">
                        <span>
                            <input :disabled="isEditingTable() || meetings.length == 0" class="form-check-input" style="cursor:pointer;" v-model="show_notes" type="checkbox" id="showMeetingNotes"/>
                            <label class="form-check-label" for="showMeetingNotes">
                                Show Notes
                            </label>
                        </span>
                    </span>-->
                    
                    <span class="me-2">
                        <!--<span style="font-size: 12px;">
                            {{displayFilterValues}}
                        </span>-->
                        <b-dropdown :disabled="isEditingTable()" size="sm" variant="outline-warning" @hidden="hidden"  menu-class="dropdown-menu-end">
                            <template #button-content>
                                <font-awesome-icon icon="fa-filter"/>
                            </template>
                            <b-dropdown-form class="p-2" >
                                <b-form-group label="Start" label-for="dropdown-form-start" @submit.stop.prevent>
                                    <b-form-input
                                    id="start-date"
                                    size="sm"
                                    type="date"
                                    placeholder="Start Date"
                                    v-model="start_date"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group label="End" label-for="dropdown-form-end" @submit.stop.prevent>
                                    <b-form-input
                                    :disabled="start_date == null"
                                    id="end-date"
                                    size="sm"
                                    type="date"
                                    placeholder="End Date"
                                    v-model="end_date"
                                    ></b-form-input>
                                    
                                    <b-row>
                                        <b-col cols="12">
                                            <b-button
                                            @click="applyFilter()"
                                            size="sm" variant="success" class="mt-2 w-100"  >
                                                Apply
                                            </b-button>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-button
                                            v-if="start_date || end_date" variant="warning"
                                            size="sm" class="mt-2 w-100" @click="resetDateFilter()"  >
                                                Clear
                                            </b-button>
                                        </b-col>

                                        <b-col cols="12">
                                            <b-button
                                            variant="primary"
                                            size="sm" class="mt-2 w-100" @click="viewForToday()"  >
                                                View For Today
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </b-dropdown-form>
                            <b-dropdown-group id="dropdown-group-1" header="Set start and end date filter">
                            </b-dropdown-group>
                        </b-dropdown>
                        
                    </span>
                    <b-button v-show="isEditingTable() == false && selected_meeting_id != -1" @click="showModal('attendees');" :disabled="hideButton" variant="primary" size="sm" class="me-2">
                        <font-awesome-icon icon="fa-plus" class="me-1 fa-lg"/>
                        Add Attendees
                    </b-button>
                </template>
            </Table>

            <!-- meeting notes -->
            <MeetingNotes v-if="show_notes" @updateNotesToggle="handleMeetingNotesToggle"/>

        </b-card>
        <ObjectivesModal
        @updatedLinkedObjectives="updateLinkedObjectivesArray"
        :meeting_id="last_clicked_meeting_id" v-if="show_objectives_modal"/>
        <AttendeesModal
        @updatedSelectedUsers="updateAttendeesArray"
        :meeting_id="last_clicked_meeting_id"
        v-if="show_modal"/>
    </div>
</template>

<script>
import _ from 'lodash';

import MeetingNotes from './meetingNotes.vue'
import AttendeesModal from './attendeesModal.vue';

import ObjectivesModal from '@/components/widgets/linkingModals/objectives.vue'
import Table from '@/components/widgets/table/table.vue'
import TableHelpers from '@/mixins/tableHelpers.js';
import dateMixin from '@/mixins/dateMixin.js'
import { levelComputed, meetingMethods, meetingState, adminComputed, } from '@/state/helpers';
//eslint-disable-next-line
import { format, parseISO } from 'date-fns';
export default {
    components:{ Table, AttendeesModal, ObjectivesModal, MeetingNotes },
    mixins:[ TableHelpers, dateMixin ],
    data:() => ({
        table_uid: "meetingsTable",
        table_view_mode: 'view',
        is_loading: false,

        show_modal: false,
        show_objectives_modal: false,
        

        last_clicked_meeting_id: -1,

        show_notes: false,

        start_date: null,
        end_date: null,
    }),
    watch:{
        table_view_mode(state){
            //flag used to block interaction with other tables while editing meetings
            this.$emit('tableBlockState', state == 'view' ? false : true);
        }
    },
    computed:{
        ...adminComputed,
        ...levelComputed,
        ...meetingState,
        hideButton(){
            return this.selected_meeting_id == -1 ? true : false;
        },
        displayFilterValues(){
            if(!this.stat_date && !this.end_date){
                return '';
            }
            else{
                let text = 'Showing Meetings From ';
                if(this.start_date){
                    text += this.start_date//parseISO(format(this.start_date), 'dd-MMM-yyyy')
                }
                if(this.end_date){
                    text += ` To ${this.end_date}`;
                }
                return text;
            }
        },
        columns(){
            let el = this;
            return [
                { id: 'title', header: 'Title',editor: 'text', fillspace: 1,},
                { id: 'meeting_date', header: 'Date', fillspace: 0.5,
                    format:function(value){
                        return el.parseDateFormat(value);
                    },
                    editor: "date", },
                { id: 'meeting_time', header: 'Time',  fillspace: 0.5,
                    editor: 'time',
                    format(value){
                        return el.parseDateTimeFormat(value);
                    },
                },
                { id: 'duration', header: 'Duration',editor: 'text',  fillspace: 0.5},
                { id: 'user_id', header: 'Organiser',editor: 'combo',  fillspace: 0.5,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: 'objective_ids', header: 'Objectives',
                    template(obj){
                        if(obj.is_empty){ return ''; }
                        let value = obj.objective_ids;
                        if(Array.isArray(value)){
                            let html = '';
                            if(el.$refs[el.table_uid].table_mode == 'edit' && obj.is_new){
                                html = `<div class="d-flex">
                                            ${value.length}
                                            <i title="Add/Remove Attendees" class="ms-2 cursor-pointer mt-auto mb-auto link-objectives-action fas fa-plus"></i>
                                        </div>`
                            }
                            else{
                                html = value.length;
                            }
                            return html;
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'attendees', header: 'Attendees',  fillspace: 0.3,
                    template(obj){
                        if(obj.is_empty){ return ''; }
                        let value = obj.attendees;
                        if(Array.isArray(value)){
                            let html = 'isArray';
                            if(el.$refs[el.table_uid].table_mode == 'edit' && obj.is_new){
                                html = `<div class="d-flex">
                                            ${value.length}
                                            <i title="Add/Remove Attendees" class="ms-2 cursor-pointer mt-auto mb-auto plus-action-icon fas fa-plus"></i>
                                        </div>`
                            }
                            else{
                                html = value.length;
                            }
                            return html;
                        }
                        else{
                            return 'else';
                        }
                    }
                }
                //{ id: 'notes',},
                //{ id: 'actions',},
                //{ id: 'actions_complete',},
                //{ id: 'link', editor: 'text',},
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'plus-action-icon':function(e, item){
                    el.last_clicked_meeting_id = item.row;
                    el.$nextTick(()=>{
                        el.showModal('attendees');
                    })
                    //let item = this.getItem(id)
                    //el.showHierarchyModal(item);
                },
                //eslint-disable-next-line
                'link-objectives-action':function(e, item){
                    el.last_clicked_meeting_id = item.row;
                    el.$nextTick(()=>{
                        el.showModal('objectives');
                    })
                }
            }
            return onClick;
        },
        default_values(){
            //let el = this;
            return [
                { id: "attendees", value: [] },
                { id: "user_id", value: this.core.id, },
                { id: "objective_ids", value: []},
                { id: "send_invites", value: false}
            ]
        },
        table_meeting_data(){
            let data = _.cloneDeep(this.meetings);
            data.forEach( meeting => {
                meeting.meeting_time = new Date(`2022-01-01 ${meeting.meeting_time}`);
                meeting['objective_ids'] = meeting.objectives.map( objective => {
                    return objective.id;
                });
            })
            return data;
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        table_validation_rules(){
            return{
                "title":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "user_id":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "duration":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "meeting_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == {} || item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "meeting_time": function(item, obj){
                    if(item == {} || obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "attendees":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item.length == 0){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "objective_ids":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item.length == 0){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        },
        
    },
    methods:{
        isEditingTable(){
            if(this.$refs[this.table_uid]){
                return this.$refs[this.table_uid].table_mode == 'edit'
            }
            else{
                return false;
            }
        },
        hidden(){
            if(!this.start_date && !this.end_date){
                return;
            }
        },
        ...meetingMethods,
        applyFilter(){
            this.getMeetings();
        },
        viewForToday(){
            this.start_date = format(new Date(), 'yyyy-MM-dd');
            this.end_date = null;
            this.getMeetings();
        },
        resetDateFilter(){
            this.end_date = null;
            this.start_date = null;
            this.getMeetings();
        },
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        selectedRowEvent(row){
            if(!row){
                this.setSelectedMeetingID(-1);
                return;
            }
            else{
            //if(this.selected_meeting_id != row.id){
                this.loadMeetingData(row.id)
                .then(()=>{
                    this.setSelectedMeetingID(row.id)
                });
            }
        },
        getFormattedDate(day) {
            const today = new Date(day);

            return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
                -2
            )}-${("0" + today.getDate()).slice(-2)}`;
        },
        saveTable(data){
            return new Promise((resolve) => {
                this.is_loading = true;
                //let promises = [];
                let payloads = [];
                let new_items = [];
                let existing_items = [];
                data.forEach( item => {
                    if(item.is_new){
                        new_items.push(item);
                    }
                    else{
                        existing_items.push(item);
                    }
                });
                //* Save newly added rows
                if(new_items.length > 0){
                    new_items.forEach( item => {
                        let payload = {
                            "objective_ids": item.objective_ids,
                            "title": item.title,
                            "meeting_date": this.getFormattedDate(item.meeting_date),
                            "meeting_time": this.parseDateTimeFormat(item.meeting_time),
                            "duration": item.duration,
                            "user_id": item.user_id,
                            "attendees": item.attendees ? item.attendees : [],
                            "send_invites": item.send_invites ? true : false,
                        }
                        // fix copied rows attendees array format (pasted rows have an array of objects)
                        payload.attendees = this.processAttendeesArray(payload.attendees);
                        payloads.push(payload);
                    })
                }
                //* Update existing rows
                if(existing_items.length > 0){
                    existing_items.forEach( item => {
                        let payload = {
                            "id": item.id,
                            "objective_ids": item.objective_ids,
                            "title": item.title,
                            "meeting_date": this.getFormattedDate(item.meeting_date),
                            "meeting_time": this.parseDateTimeFormat(item.meeting_time), //format(item.meeting_time, "H:mm")
                            "duration": item.duration,
                            "user_id": item.user_id,
                            "attendees": item.attendees ? item.attendees : [],
                            "send_invites": item.send_invites ? true : false,
                        }
                        payload.attendees = this.processAttendeesArray(payload.attendees);
                        payloads.push(payload);
                    })
                }
                //resolve all promises 
                if(payloads.length > 0){
                    Promise.all(payloads.map( param => param.id == undefined ? this.createMeeting(param) : this.updateMeeting(param)))
                    .then(()=>{
                        this.$nextTick(()=>{
                            this.getMeetings()
                            .then(()=>{
                                this.$refs[this.table_uid].table_mode = 'view';
                                this.is_loading = false;
                                resolve();
                            })
                            .catch(()=>{
                                this.is_loading = false;
                            })
                        })
                    })
                    .catch(()=>{
                        this.is_loading = false;
                    })
                }
                else{
                    this.is_loading = false;
                    resolve();
                }
            })
        },
        processAttendeesArray(attendees_array){
            if(attendees_array[0] ==undefined){
                return [];
            }
            else{
                if(isNaN(attendees_array[0])){
                    // process array of objects to normal array of id's
                    return attendees_array.map( item => {
                        return item.id
                    })
                }
                else{
                    // is already in single number format
                    return attendees_array;
                }
            }
        },
        updateAttendeesArray(payload){ //* {users, send_invites}
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.last_clicked_meeting_id);
            //* update fields
            item.attendees = payload.users;
            item['send_invites'] = payload.send_invites;

            table.updateItem(this.last_clicked_meeting_id, item);
            item = table.getItem(this.last_clicked_meeting_id);
            this.$bvModal.hide('attendeesModal');
            if(item.is_new == undefined){
                item['send_invites'] = payload.send_invites;
                this.saveTable([item])
                .then(()=>{
                    this.loadMeetingData(this.last_clicked_meeting_id)
                    .then(()=>{
                        this.$eventHub.$emit('forceObjectiveHighlight');
                    })
                })
            }
        },
        updateLinkedObjectivesArray(id_array){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.last_clicked_meeting_id);
            item.objective_ids = id_array;
            table.updateItem(this.last_clicked_meeting_id, item);
            item = table.getItem(this.last_clicked_meeting_id);
            this.$bvModal.hide('objectivesModal');
            if(item.is_new == undefined){
                this.saveTable([item])
                .then(()=>{
                    this.loadMeetingData(this.selected_meeting_id)
                    .then(()=>{
                        this.$eventHub.$emit('forceObjectiveHighlight');
                    })
                })
            }
        },
        showModal(type){
            if(this.$refs[this.table_uid].table_mode == 'view'){
                this.last_clicked_meeting_id = this.selected_meeting_id;
            }
            let item = window.webix.$$(this.table_uid).getItem(this.last_clicked_meeting_id);
            if(this.$refs[this.table_uid].table_mode != 'view' && !item.is_new){
                this.$swal.fire('Action unavailable while the table is in Edit mode')
                return;
            }
            switch(type){
                case "attendees":
                    this.show_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('attendeesModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "attendeesModal") {
                                this.show_modal = false;
                            }
                        });
                    })
                    break;

                case "objectives":
                    this.show_objectives_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('objectivesModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "objectivesModal") {
                                this.show_objectives_modal = false;
                            }
                        });
                    })
                    break;
            }
        },
        async deleteRow(data){
            //let meeting_ids = data.map( meeting => { return meeting.id})
            //let promises = [];
            //Promise.all( data.map( param => this.deleteMeeting(param)))
            //.then(()=>{
            //    this.getMeetings()
            //    .then(()=>{
            //        this.$refs[this.table_uid].table_mode = 'view';
            //    })
            //})
            let params = data.map( item => { return {...item, force_delete: false}})
            this.deleteMeeting({meetings:params})
            .then( async (response) =>{
                let failed_count = 0;
                let results = response.data.results;
                const keys = Object.keys(results);
                for(let i = 0; i< keys.length; i++){
                    console.log("K", results, keys[i])
                    if(results[keys[i]] == false){
                        failed_count++;
                    }
                }
                //* if failed count = 0 thn we can reload like normal
                if(failed_count == 0){
                    this.getMeetings()
                    .then(()=>{
                        this.$refs[this.table_uid].table_mode = 'view';
                    })
                }
                else{
                    for(let i = 0; i< keys.length; i++){
                    if(results[keys[i]] == false){
                        await this.handleRowDelete(keys[i]);
                    }
                    this.getMeetings()
                    .then(()=>{
                        this.$refs[this.table_uid].table_mode = 'view';
                    })
                }
                }
            })
        },
        handleRowDelete(id){
            return new Promise( (resolve) =>  {
                const index = _.findIndex(this.table_meeting_data, {id: Number(id)})
                const name = this.table_meeting_data[index].title;
                this.$swal.fire({
                    title: name,
                    text: 'Meeting has linked data, force delete?',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                })
                .then( result =>{
                    if(result.value){
                        let payload = {
                            meetings:[
                                {
                                    id: Number(id),
                                    force_delete: true,
                                }
                            ]
                        }
                        this.deleteMeeting(payload)
                        .then(()=>{
                            resolve('Said yes');
                        })
                    }
                    else{
                        resolve()
                    }
                })
            })
        },
        getMeetings(){
            return new Promise((resolve, reject) => {
                let reload_params = {}
                if(this.start_date){
                    reload_params['start_date'] = this.start_date//format(parseISO(this.start_date), 'dd-MM-yyyy');
                    if(this.end_date){
                        reload_params['end_date'] = this.end_date//format(parseISO(this.end_date), 'dd-MM-yyyy');
                    }
                }
                this.loadMeetings(reload_params)
                .then(()=>{ resolve() })
                .catch(()=>{ reject() });
            })
            
        },
        handleMeetingNotesToggle(newValue){
            this.show_notes = newValue;
        },
        showObjectivesModalEvent(){
            this.showModal('objectives')
        }
    },
    mounted(){
        this.$eventHub.$on('showObjectivesModal', this.showObjectivesModalEvent)
        //this.getMeetingNotes({
        //    meeting_id: 7,
        //    user_id: 80,
        //})
    },
    beforeDestroy(){
        this.$eventHub.$off('showObjectivesModal')
    },
}
</script>

<style>
    .plus-action-icon{
        cursor: pointer;
    }
    .plus-action-icon:hover{
        color: var(--bs-success);
    }

    .link-objectives-action{
        cursor: pointer;
    }
    .link-objectives-action:hover{
        color: var(--bs-primary);
    }
</style>