<template>
    <b-modal title="Linked Objectives" id="objectivesModal" hide-footer>
        <b-overlay :show="!hasMounted">
            <div class="d-flex">
                <b-form-input
                    size="sm"
                    v-model="search_text"
                    placeholder="Search objectives"
                    @keydown.enter.native="searchText"
                    >
                </b-form-input>
                <b-button v-if="search_text != ''" class="ms-2" size="sm" variant="success">
                    <font-awesome-icon @click="search_text = ''" icon="fa-solid  fa-search" />
                </b-button>
                <b-button v-if="search_text != ''" class="ms-2" size="sm" variant="danger">
                    <font-awesome-icon @click="search_text = ''" icon="fa-solid  fa-times" />
                </b-button>
            </div>
            
            <webix-ui  style="height: 100%;" v-model="available_objectives" :config="ui" ></webix-ui>
            <div class="d-flex">
                <b-button @click="saveSelection" class="ms-auto mt-2" variant="success">Save</b-button>
            </div>
        </b-overlay>
        
    </b-modal>
</template>

<script>
import _ from 'lodash';
import { meetingMethods, meetingState, levelComputed, } from '@/state/helpers'
export default {
    data:() => ({
        hasMounted: false,
        available_objectives: [],
        search_text:'',
        tree_state: null,
    }),
    props:{
        meeting_id:{
            required: true,
        }
    },
    watch:{
        hasMounted(){
            this.$nextTick(()=>{
                this.enableSelectedItems();
            })
        },
        search_text(){
            this.searchText();
        }
    },
    computed:{
        ...meetingState,...levelComputed,
        ui(){
            return{
                id: 'linkingObjectivesTree',
                view: 'tree',
                css: '',
                drag: false,
                autoheight: true,
                tooltip: true,
                minHeight: 300,
                data: this.available_objectives,
                template: "{common.icon()}{common.checkbox()} #value#",
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                    let el = this.$scope.$parent;
                    el.hasMounted = true;
                },
                on:{
                    onItemCheck(id, value, event){
                        //* event will be undefined if setting checkbox value through api
                        if(event != undefined){
                            // user clicked checkbox :)
                            //if(value){ //* if true then we must ensure all immediate parents are also enabled
                            //    let parent_id = this.getItem(id).$parent;
                            //    while(parent_id != 0){
                            //        if(!this.isChecked(parent_id)){
                            //            this.checkItem(parent_id);
                            //        }
                            //        parent_id = this.getItem(parent_id).$parent;
                            //    }
                            //}
                            //else{ //* if false then alll selected children must be deselected
                            //    this.data.eachSubItem( id,(children) => {
                            //        if(this.isChecked(children.id)){
                            //            this.uncheckItem(children.id)
                            //        }
                            //    })
                            //}
                        }
                    },
                },
            }
        }
    },
    methods:{
        ...meetingMethods,
        saveSelection(){
            this.hasMounted = false;
            let checked_items = window.webix.$$('linkingObjectivesTree').getChecked();
            //let selected_users = this.user_list.filter( item => {
            //    return item.is_selected;
            //});
            //selected_users = selected_users.map(({id}) => Number(id) )
            this.$emit('updatedLinkedObjectives', checked_items);
        },
        processTree(){
            let data = _.cloneDeep(this.meeting_objectives);
            //data = _.orderBy(data, ['item_order']);
            data.forEach((element) => {
                element["parent_id"] = element.parent_objective_id;
                element["parent"] = element.parent_id;
                element["value"] = element.name; //used for webix related stuff
                element['data'] = []; // array of children for the node
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id == null) {
                    //* Parent item -- do nothing
                } 
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;

                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }

                        }
                    });
                }
            });
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            //*Set parent root as expanded;
            /*if(this.data.length > 0){
                data[0]["opened"] = true;
                data[0]["open"] = true;
            }*/
            this.available_objectives = data;
            this.hasMounted = true;
        },
        enableSelectedItems(){
            let row = window.webix.$$('meetingsTable').getItem(this.meeting_id)
            console.log(row.objectives)
            if(row){
                setTimeout(()=>{
                    let tree = window.webix.$$('linkingObjectivesTree');
                    if(!tree){
                        return;
                    }
                    if(!row.objectives){
                        //* new row has objectives == null and not an array
                        return;
                    }
                    row.objectives.forEach( item => {
                        let current_item = tree.getItem(item.id);
                        if(current_item){
                            while(current_item.$parent != 0){
                                //console.log('WHILE LOG y')
                                tree.open(current_item.$parent);
                                current_item = tree.getItem(current_item.$parent);
                            }
                            tree.checkItem(item.id);
                        }
                    })
                }, 500)
            }
        },
        searchText(){
            if(this.search_text != ''){
                window.webix.$$('linkingObjectivesTree').filter("#value#", this.search_text);
            }
            else{
                window.webix.$$('linkingObjectivesTree').filter();
                this.enableSelectedItems();
            }
        }
    },
    mounted(){
        
        if(this.meeting_objectives.length > 0){
            this.processTree();
        }
        else{
            this.loadMeetingObjectives(this.current_level_id)
            .then(()=>{
                this.processTree();
            })
        }
    },
}
</script>

<style>

</style>