<template>
    <b-card>
        <b-overlay :style="setComponentHeight" :show="finished_loading == false">
            <CardHeader>
                <template #title>
                    Notes
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-success">{{ selectedMeetingName }}</span>
                    <!--&nbsp;&nbsp;>&nbsp;&nbsp;
                    <b-dropdown size="sm" variant="outline-primary" :text="getNoteOwnerName()">
                        <b-dropdown-item @click="setNotes(note.user_id)" v-for="note in available_notes" :key="note.id">
                            {{getNoteOwnerName(note.user_id)}}
                        </b-dropdown-item> 
                    </b-dropdown>-->
                </template>
                <template #buttons>
                    <div class="btn-group">
                        <b-button disabled size="sm" variant="light">User Notes:</b-button> 
                        <b-dropdown class="me-2" size="sm" menu-class="dropdown-menu-end" variant="outline-primary">
                            <template #button-content>
                                <font-awesome-icon class="fa-fw me-1" icon="fa-caret-down"/>
                                {{getNoteOwnerName()}}
                            </template>
                            <b-dropdown-item v-show="filtered_notes.length > 0" @click="setAllNotes()">
                                All Notes
                            </b-dropdown-item> 
                            <b-dropdown-item v-show="filtered_notes.length > 0" @click="setNotes(note.user_id)" v-for="note in filtered_notes" :key="note.id">
                                {{getNoteOwnerName(note.user_id)}}
                            </b-dropdown-item> 
                            <b-dropdown-header v-show="filtered_notes.length == 0">
                                No other user notes available
                            </b-dropdown-header>
                        </b-dropdown>
                    </div>
                    <!--
                    <span class="form-check form-switch pt-1 me-3 ms-2" style="font-size: 10px;">
                        <span>
                            <input  class="form-check-input" style="cursor:pointer;" v-model="show_notes" type="checkbox" id="showMeetingNotes"/>
                            <label class="form-check-label" for="showMeetingNotes">
                                Show Notes
                            </label>
                        </span>
                    </span>
                    -->
                    <span>
                        <b-button @click="saveNotes" variant="primary" size="sm" v-if="has_changes && isOwnNote">Save Changes</b-button>
                    </span>
                    <!--<b-dropdown size="sm" menu-class="dropdown-menu-end" text="View Attendee Notes">
                        <b-dropdown-item @click="setNotes(note.user_id)" v-for="note in available_notes" :key="note.id">
                            {{note.id}}
                        </b-dropdown-item> 
                    </b-dropdown>-->
                </template>
            </CardHeader>
            <ckeditor v-model="editor_data" :read-only="isReadOnly" :config="editorConfig" @ready="onEditorReady"></ckeditor>
        </b-overlay>
    </b-card>
</template>

<script>
import { cloneDeep } from 'lodash'
//* editor component
import CardHeader from '@/components/widgets/cardHeader.vue';
import CKEditor from 'ckeditor4-vue';
//import _ from 'lodash'
import { meetingMethods, meetingState, adminComputed, levelComputed} from '@/state/helpers';
export default {
    components: {
        CardHeader,
        ckeditor: CKEditor.component
    },
    data:() => ({
        show_notes: true,
        block_edit_flag: false,
        editor_data: '',
        origional_editor_data: '',
        selected_note_owner_id: -1,
        //* var to hold editors context
        editor: null,
        //* configuration options for ckeditor4
        editorConfig: {
            contentsCss: [
                '/css/ckeditor4.css',
                'https://cdn.ckeditor.com/4.19.1/standard-all/plugins/tableselection/styles/tableselection.css',
            ],
            fontSize_defaultLabel: '10',
            extraPlugins: 'tableresize',
            toolbarGroups: [
                { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                { name: 'editing', groups: [ 'spellchecker', 'find', 'selection', 'editing' ] },
                { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                { name: 'links', groups: [ 'links' ] },
                { name: 'styles', groups: [ 'styles' ] },
                { name: 'colors', groups: [ 'colors' ] },
                { name: 'tools', groups: [ 'tools' ] },
                { name: 'others', groups: [ 'others' ] },
            ],
            removeButtons: 'Source,Save,Preview,Print,NewPage,Templates,SelectAll,Anchor,Flash,Image,Smiley,PageBreak,Iframe,Language,CreateDiv,ShowBlocks,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField',
        },
        finished_loading: false,
    }),
    watch:{
        show_notes(newValue){
            this.$emit('updateNotesToggle', newValue)
        },
        meeting_data(){
            if(this.selected_note_owner_id == -1){
                this.setAllNotes();
            }
            else{
                this.setNotes();
            }
            //this.selected_note_owner_id = -1;
            //this.setNotes();
            
        }
    },
    computed:{
        ...meetingState, ...adminComputed, ...levelComputed,
        selectedMeeting(){
            return this.meetings.find( item => {
                return item.id == this.selected_meeting_id;
            });
        },
        isOwnNote(){
            return this.core.id == this.selected_note_owner_id;
        },
        isReadOnly(){
            if(this.selected_note_owner_id == -1){
                return true;
            }
            return this.core.id != this.selected_note_owner_id;
        },
        selectedMeetingName(){
            if(this.selectedMeeting == null){
                return 'No Meeting Selected'
            }
            else{
                return this.selectedMeeting.title;
            }
        },
        availableNoteOwners(){
            let data = [];
            if(this.selected_meeting_id == -1){
                return data;
            }
            else{
                let meeting = this.meetings.find( item => {
                    return item.id == this.selected_meeting_id;
                })
                if(meeting){
                    data = meeting.notes;
                }
            }
            return data;
        },
        has_changes(){
            let value = ''
            if(this.block_edit_flag){
                value = false;
            }
            else{
                value = this.origional_editor_data != this.editor_data;
            }
            return value;
        },
        setComponentHeight(){
            return this.finished_loading == false ? 'min-height: 10rem;' : '';
        },
        available_notes(){
            let notes = [];
            if(this.meeting_data && this.meeting_data.notes){
                notes = this.meeting_data.notes;
            }
            return notes
            //return this.meeting_data?.notes ? this.meeting_data.notes : [];
        },
        filtered_notes(){
            return this.available_notes.filter( note => {
                return note.user_id != this.selected_note_owner_id
            })
        }
    },
    methods:{
        ...meetingMethods,
        getNoteOwnerName(user_id = this.selected_note_owner_id){
            if(user_id == -1){
                return 'All Notes';
            }
            let text = 'N/A';
            let user = this.all_users.find( item => {
                return item.id == user_id
            });
            if(user){
                text = user.name;
            }
            return text;
        },
        onEditorReady(editor) {
            this.editor = editor;
            this.finished_loading = true;
        },
        setAllNotes(){
            this.selected_note_owner_id = -1;
            this.block_edit_flag = true;
            this.editor_data = "";
            this.origional_editor_data = "";
            this.available_notes.forEach( note => {
                this.editor_data += `<div><strong>${this.getNoteOwnerName(note.user_id)}</strong></div>`
                if(note.text == null){
                    this.editor_data += "No notes added"
                }
                else{
                    this.editor_data += note.text;
                }
                this.editor_data += '<hr>'
            })
            this.$nextTick(()=>{
                this.origional_editor_data = cloneDeep(this.editor_data);
                if(this.origional_editor_data == null){
                    this.origional_editor_data = '';
                }
                else{
                    this.origional_editor_data += '\n';
                }
                setTimeout(()=>{
                    this.block_edit_flag = false;
                },250)
            })

        },
        setNotes(user_id = this.core.id){
            this.block_edit_flag = true;
            this.$nextTick(()=>{
                this.selected_note_owner_id = user_id;
                this.editor_data = "";
                this.origional_editor_data = "";
                if(this.selected_meeting_id != -1){
                    let current_user_notes = this.available_notes.find( note =>{
                        return note.user_id == this.selected_note_owner_id;
                    })
                    if(current_user_notes){
                        this.editor_data = current_user_notes.text;
                        this.$nextTick(()=>{
                            this.origional_editor_data = cloneDeep(this.editor_data);
                            if(this.origional_editor_data == null){
                                this.origional_editor_data = '';
                            }
                            else{
                                this.origional_editor_data += '\n';
                            }
                        })
                    }
                }
                setTimeout(()=>{
                    this.block_edit_flag = false;
                },200)
            })
            
        },
        saveNotes(){
            this.finished_loading = false;
            let payload = {
                meeting_id: this.selected_meeting_id,
                user_id: this.core.id,
                text: this.editor_data,
            }

            this.saveMeetingNotes(payload)
            .then(()=>{
                this.loadMeetingData(this.selected_meeting_id)
                .then(()=>{
                    this.$swal.fire({
                        icon:'success',
                        title:'Saved!',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.setNotes(this.selected_note_owner_id);
                    this.$nextTick(()=>{
                        this.finished_loading = true;
                    })
                })
            })
        }
    },
    mounted(){
        this.setAllNotes();
        //this.setNotes();
    },
}
</script>

<style>

</style>