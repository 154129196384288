<template>
    <div v-if="show">
        <b-row v-if="this.selected_user_id != null">
            <b-col cols="12">
                <b-card class="bg-info bg-soft d-flex">
                    <h5 class="p-0 m-0">{{returnSelectedUserName}}</h5>
                </b-card>
            </b-col>
        </b-row>
        <!--<b-button @click="debugAction">Debug</b-button>-->
        <div v-show="has_mounted == false">
            <b-alert show >Loading Meeting Data</b-alert>
        </div>
        <b-overlay  :show="has_mounted == false">
            <content v-if="has_mounted">
                <b-alert variant="danger" :show="selected_meeting_id == -1">
                    Select a <strong>Meeting Event</strong> to view Notes and Activities.
                </b-alert>

                <MeetingsTable @tableBlockState="handleBlockState"  />

                <b-overlay variant="white" :show="block_content_flag">
                    <template #overlay>
                        Content disabled while the Meetings table is in edit mode
                    </template>
                    <!-- overlay used to 'blur -->
                    <Notes v-if="selected_meeting_id != -1" />
                    <MeetingsObjectivesTable/>

                    <div v-if="selected_meeting_id != -1">
                        <ActionPlan v-if="canShowActivityTable"/>
                        <b-alert :show="canShowActivityTable == false" variant="danger">
                            <strong>
                                Action plans are hidden because a company level is selected.
                            </strong>
                        </b-alert>
                    </div>
                </b-overlay>
            </content>
        </b-overlay>
    </div>
</template>

<script>
import MeetingsObjectivesTable from './meetingObjectives.vue'
import MeetingsTable from './meetingsTable.vue'
import ActionPlan from './meetingActionPlan.vue'
import Notes from './meetingNotes.vue'
import { meetingMethods, meetingState, levelComputed, adminComputed, activityComputed, activityMethods, } from '@/state/helpers';
export default {
    data:() => ({
        has_mounted: false,
        show: true,
        block_content_flag: false,
    }),
    components:{
        MeetingsObjectivesTable,
        MeetingsTable,
        Notes,
        ActionPlan
    },
    watch:{
        current_level_id(){
            this.loadMeetingObjectives(this.current_level_id);
        },
        pinned_milestone_id(){
            this.loadMeetingObjectives(this.current_level_id);
        },
        selected_user_id(){
            this.getMeetings()
        },
        //selected_meeting_id(new_id){
        //    if(new_id != -1){
        //        this.loadMeetingActivities({meeting_id: new_id});
        //    }
        //},
        //selected_meeting_objective_id(newValue){
            //if(newValue != -1){
            //    let params = {
            //        objective_id: newValue,
            //        user_id: -1,
            //    }
            //    this.loadMeetingActivities(params);
            //}
        //},
    },
    computed:{
        ...levelComputed,
        ...meetingState,
        ...adminComputed,
        ...activityComputed,
        returnSelectedUserName(){
            if(this.selected_user_object == null){
                return ''
            }
            else{
                return `${this.selected_user_object.name} ${this.selected_user_object.last_name}`
            }
        },
        canShowActivityTable(){
            return this.company_id != this.current_level_id
        }
    },
    methods:{
        ...activityMethods,
        ...meetingMethods,
        handleBlockState(state){
            this.block_content_flag = state;
        },
        debugAction(){
            this.show = false;
            this.$nextTick(()=>{
                this.show = true;
            })
        },
        getMeetings(){
            //let params = {
            //    level_id: this.current_level_id,
            //}
            //if(this.selected_user_id != null){
            //    params.user_id = this.selected_user_id
            //}
            let payload = {};
            if(this.selected_user_id != null){
                payload['user_id'] = this.selected_user_id;
            }
            return new Promise( resolve => {
                this.loadMeetings(payload)
                .then(()=>{
                    resolve();
                })
            })
        },
        getMeetingRelatedData(){
            let promises = [
                this.getMeetings(),
                this.loadMeetingObjectives(this.current_level_id)
            ]
            Promise.all(promises)
            .then(()=>{ this.$nextTick(()=>{ this.has_mounted = true; }); })
        }
    },
    mounted(){
        if(this.company_milestones.length == 0){
            this.loadCompanyMilestones(this.company_id)
            .then(()=>{
                this.getMeetingRelatedData();
            })
        }
        else{
            this.getMeetingRelatedData();
        }
        //if(this.meetings.length == 0){
            
        //}
        //else{
            //this.$nextTick(()=>{ this.has_mounted = true; });
        //}
    },
    beforeDestroy(){
        this.clearMeetingStore();
    }
}
</script>

<style>

</style>