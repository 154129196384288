<template>
    <b-modal id="scheduleModal" hide-footer>
        <template #modal-title>
            <b-button size="sm" variant="white" v-show="show_table == false" @click="resetView()">
                <i class="fas fa-arrow-left"/>
            </b-button>
            {{modalTitle}}
        </template>
        <div v-if="show_table">
            <ScheduleTable
                @newReminder="createNewReminder()"
                @editReminder="editReminder"
                @deleteItem="handleDelete"
            />
        </div>

        <div v-else>
            <p class="cursor-pointer" @click="toggleCollapse()">
                <i class="fas me-2" :class="top_collapse ? 'fa-caret-up' : 'fa-caret-down'"></i>
                <strong>Schedule Configuration</strong>
            </p>
            <b-collapse v-model="top_collapse">
                <b-row class="mb-2">
                    <b-col cols="6">
                        <label for="">Start Date</label>
                        <b-form-input v-model="reminder_data.start_date" size="sm" type="date"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label for="">Start Time</label>
                        <b-form-input v-model="reminder_data.start_time" size="sm" type="time"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="6">
                        <label for="">End Date</label>
                        <b-form-input v-model="reminder_data.end_date" size="sm" type="date"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label for="">End Time</label>
                        <b-form-input v-model="reminder_data.end_time" size="sm" type="time"></b-form-input>
                    </b-col>
                </b-row>
                <hr/>
                <b-row class="mb-2">
                    <b-col cols="4">
                        <label for="">Active</label>
                        <span class="form-check me-3 ms-1">
                            <span>
                                <input  class="form-check-input" style="cursor:pointer;" v-model="reminder_data.active" type="checkbox" id="activeCheckbox"/>
                            </span>
                        </span>
                    </b-col>
                    <b-col cols="8">
                        <label for="">Email Template</label>
                        <b-form-select v-model="reminder_data.client_email_template_id" size="sm" class="make-sm form-select" :options="template_options"></b-form-select>

                    </b-col>
                </b-row>

                <b-row class="mb-2">
                    <b-col cols="6">
                        <label for="">Recurrence</label>
                        <b-form-input v-model="reminder_data.recurrence" size="sm" type="number"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label for="">Recurrence Period</label>
                        <b-form-select v-model="reminder_data.recurrence_period_id" size="sm" class="make-sm form-select" :options="recurrence_period_ids"></b-form-select>
                    </b-col>
                </b-row>

                <b-row class="mb-2">
                    <b-col cols="6">
                        <label for="">Send Before</label>
                        <b-form-input v-model="reminder_data.send_before" size="sm" type="number"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label for="">Send Before Period</label>
                        <b-form-select v-model="reminder_data.send_before_period_id" size="sm" class="make-sm form-select" :options="recurrence_period_ids"></b-form-select>
                    </b-col>
                </b-row>
            </b-collapse>
            <hr>
            <p class="cursor-pointer" @click="toggleCollapse()">
                <i class="fas me-2" :class="bottom_collapse ? 'fa-caret-up' : 'fa-caret-down'"></i>
                <strong>Recipients List</strong>
            </p>
            <b-collapse v-model="bottom_collapse">
                <vue-typeahead-bootstrap
                ref="recipientsTypeahead"
                :data="typeahead_users"
                v-model="query"
                placeholder="Search name/email"
                showOnFocus
                @hit="selectedItemEvent"
                prepend="Users:"
            ></vue-typeahead-bootstrap>
                <b-row class="mt-2 mb-2" v-show="recipients_list.length == 0">
                    <b-col >
                        <b-alert show >No recipients added</b-alert>
                    </b-col>
                </b-row>
                <b-row class="mb-2" v-show="recipients_list.length > 0">
                    <b-col cols="12" v-for="recipient in recipients_list" :key="recipient.id" class="d-flex">
                        <div class="mt-1 mb-1">{{recipient.name}} ({{recipient.email}})</div>
                        <b-dropdown size="sm" class="ms-auto me-1" :text="getRecipientTypeName(recipient.recipient_type_id)">
                            <b-dropdown-item v-for="type in recipient_types" :key="type.id" @click="recipient.recipient_type_id = type.id">
                                {{type.name}}   
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-button variant="danger" size="sm" @click="removeRecipient(recipient.id)">
                            <i class='fas fa-trash'/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-collapse>
            <div class="d-flex">
                <b-button @click="saveReminder()" class="ms-auto mt-2" variant="success">Save</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import ScheduleTable from './scheduleListTable.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { meetingState, meetingMethods, levelComputed, adminComputed, adminMethods, } from "@/state/helpers.js";
export default {
    components:{
        VueTypeaheadBootstrap,
        ScheduleTable,
    },
    props:{
        action_plan_id:{},
    },
    data:() => ({
        //typeahead
        typeahead_users: [],
        query: '',
        recipients_list:[],

        top_collapse: true,
        bottom_collapse: false,

        show_table: true,
        is_new_reminder: false,

        reminder_data:{
            type : "meeting_reminder",
            eventable_id : 196,
            client_email_template_id : null,
            active : 1,
            start_date : null,
            start_time : null,
            end_date : null,
            end_time : null,
            recurrence : null,
            recurrence_period_id : null,
            send_before : null,
            send_before_period_id : null,
            recipients : [],
            organizer_user_id: -1,
        }
    }),
    computed:{
        ...meetingState,
        ...levelComputed,
        ...adminComputed,
        modalTitle(){
            if(this.show_table){
                return 'Added Reminders';
            }
            else{
                return this.is_new_reminder ? 'New Reminder' : 'Edit Reminder';
            }
        },
        template_options(){
            let opts = this.reminder_email_templates.map( template => {
                return {
                    value: template.id,
                    text: template.name,
                }
            })
            opts.push({ value: null, text: 'Choose option'},);
            return opts;
        },
        recurrence_period_ids(){
            return [
                { value: null, text: 'Choose option'},
                { value: 1, text: 'Daily' },
                { value: 2, text: 'Weekly' },
                { value: 3, text: 'Monthly' },
            ]
        }
    },
    methods:{
        ...adminMethods,
        ...meetingMethods,
        save(){
            this.addMeetingReminder(this.reminder_data);
        },
        handleDelete(id){
            this.deleteMeetingReminder(id.row)
            .then(()=>{
                this.loadMeetingData(this.selected_meeting_id)
            })
        },
        toggleCollapse(){
            this.top_collapse = !this.top_collapse;
            this.bottom_collapse = !this.bottom_collapse;
        },
        getRecipientTypeName(id){
            let type = this.recipient_types.find( item => item.id == id);
            return type.name;
        },
        selectedItemEvent(item){
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let email = item.split('| ')[1];
            let user = this.all_users.find( user => {
                return user.email == email;
            });
            if(user){
                let existing = this.recipients_list.findIndex( item => item.id == user.id)
                if(existing == -1){
                    this.recipients_list.push({
                        name: user.name,
                        email: user.email,
                        id: user.id,
                        recipient_type_id: 1
                    });
                    this.resetTypeahead();
                }
                else{
                    this.resetTypeahead();
                }
                
            }
        },
        removeRecipient(id){
            let index = this.recipients_list.findIndex( item => {
                return id == item.id;
            });
            if(index != -1){
                this.recipients_list.splice(index, 1);
            }
        },


        saveReminder(){
            this.reminder_data.recipients = this.recipients_list.map( item => {
                return {
                    email: item.email,
                    recipient_type_id : item.recipient_type_id,
                }
            })
            this.reminder_data.recurrence = Number(this.reminder_data.recurrence);
            this.reminder_data.send_before = Number(this.reminder_data.send_before);
            if(this.is_new_reminder){
                this.addMeetingReminder(this.reminder_data)
                .then(()=>{
                    this.loadMeetingData(this.selected_meeting_id)
                    .then(()=>{
                        this.resetView();
                    })
                })
            }
            else{
                this.updateMeetingReminder(this.reminder_data)
                .then(()=>{
                    this.loadMeetingData(this.selected_meeting_id)
                    .then(()=>{
                        this.resetView();
                    })
                })
            }
            
        },

        //* Table events
        createNewReminder(){
            this.reminder_data = {
                type : "meeting_reminder",
                eventable_id : this.selected_meeting_id,
                client_email_template_id : null,
                active : 1,
                start_date : null,
                start_time : null,
                end_date : null,
                end_time : null,
                recurrence : null,
                recurrence_period_id : null,
                send_before : null,
                send_before_period_id : null,
                recipients : [],
                organizer_user_id: this.core.id,
            }
            this.is_new_reminder = true;
            this.show_table = false;
        },
        editReminder(payload){
            //this.reminder_data = cloneDeep(payload);
            this.reminder_data = {
                id: payload.id,
                type : "meeting_reminder",
                eventable_id : payload.eventable_id,
                client_email_template_id : payload.client_email_template_id,
                active : payload.active,
                start_date : payload.start_date,
                start_time : payload.start_time,
                end_date : payload.end_date,
                end_time : payload.end_time,
                recurrence : payload.recurrence,
                recurrence_period_id : payload.recurrence_period_id,
                send_before : payload.send_before,
                send_before_period_id : payload.send_before_period_id,
                recipients : [],
                organizer_user_id : payload.user_id,
            }
            this.recipients_list = payload.recipients.map( item => {
                return {
                    name: this.getUserNameFromID(item.user_id),
                    email: this.getUserEmailFromID(item.user_id),
                    id: item.user_id,
                    recipient_type_id: item.schedule_recipient_type_id,
                }
            }),

            this.is_new_reminder = false;
            this.show_table = false;
        },

        //* misc methods
        getUserNameFromID(id){
            let user = this.all_users.find( user => user.id == id);
            if(user){
                return user.name;
            }
            return '';
        },
        getUserEmailFromID(id){
            let user = this.all_users.find( user => user.id == id);
            if(user){
                return user.email;
            }
            return '';
        },
        resetView(){
            this.show_table = true;
            this.top_collapse = true;
            this.bottom_collapse = false;
        },
        populateTypeahead(){
            let users = [];
            this.all_users.forEach( user =>{
                users.push(`${user.name} | ${user.email}`)
            });
            this.typeahead_users = users;
        },
        resetTypeahead(){
            this.$nextTick(()=>{
                this.query = '';
                let input = this.$refs['recipientsTypeahead'].$refs['input'];
                input.blur();
            })
        },
    },
    mounted(){
        if(this.reminder_email_templates.length == 0){
            this.loadReminderEmailTemplates();
        }
        if(this.recipient_types.length == 0){
            this.loadRecipientTypes();
        }
        this.populateTypeahead();
        this.reminder_data.organizer_user_id = this.core.id;
        this.reminder_data.eventable_id = this.selected_meeting_id
    },
}
</script>

<style>
.make-sm{
    height: 25px;
    padding: 0 !important;
}
</style>