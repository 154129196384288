<template>
    <b-modal size="lg" title="Meeting Attendees" id="attendeesModal" hide-footer="">
        <vue-typeahead-bootstrap
            ref="attendeesSearch"
            :data="users"
            v-model="query"
            placeholder="Search name/email"
            showOnFocus
            @hit="selectedItemEvent"
            prepend="Users:"
        ></vue-typeahead-bootstrap><!--@hit="selectJob($event)"-->



        <hr>
        <!--<div class="d-flex">
            <b-form-input v-model="search_text" size="sm" placeholder="Search user"></b-form-input>
            <b-button lazy @click="search_text = ''" size="sm" class="ms-1" variant="danger">
                <i class="fas fa-times"></i>
            </b-button>
        </div>-->
        <div style="height: 65vh; overflow-y: auto;" class="table-responsive mb-0">
            <b-alert variant="primary" :show="user_list.length == 0">
                All users that will be attending the meeting
            </b-alert> 
            <table v-show="user_list.length > 0" style="" class="table table-centered table-nowrap">
                <thead>
                    <tr>
                        <th>User (email)</th>
                        <th class="">
                            <div class="d-flex">
                                <span class="ms-auto form-check form-switch pt-1 me-3 ms-2" style="font-size: 11px;">
                                    <span> <!-- :disabled="is_editing"-->
                                        <input  class="form-check-input" style="cursor:pointer;" v-model="invite_attendees" type="checkbox" id="forceAttendees"/>
                                        <label class="form-check-label" for="forceAttendees">
                                            Invite Attendees
                                        </label>
                                    </span>
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="thead-light">
                    <tr v-for="user in user_list" :key="user.id" :class="user.is_selected ? 'bg-success bg-soft' : ''" >
                        <td style="width: 60px;">
                            <div class="custom-control custom-checkbox">
                                <input  v-model="user.is_selected" :id="`customCheck-${user.id}`" type="checkbox" class="custom-control-input" />
                                <label class="custom-control-label" :for="`customCheck-${user.id}`"></label>
                            </div>
                        </td>
                        <td style="cursor: pointer;" @click="user.is_selected = !user.is_selected">{{user.name}} ({{user.email}})</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex">
            <b-button @click="saveSelection" class="ms-auto mt-2" variant="success">Save</b-button>
        </div>
    </b-modal>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import _ from 'lodash';
import { levelComputed, meetingMethods, meetingState } from '@/state/helpers';
export default {
    components:{
        VueTypeaheadBootstrap
    },
    data:() => ({
        user_list: [],
        search_text: '',
        users:[],
        query: '',
        invite_attendees: true,
    }),
    props:{
        meeting_id:{
            required: true,
        }
    },
    computed:{
        ...levelComputed, ...meetingState,
    },
    methods:{
        ...meetingMethods,
        selectedItemEvent(item){
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let email = item.split('| ')[1];
            let user = this.all_users.find( user => {
                return user.email == email;
            });
            
            if(user){
                let attendees_list_index = this.user_list.findIndex( list_user => {
                    return list_user.id == user.id;
                });
                //* 
                if(attendees_list_index > -1){
                    this.$swal.fire('User already selected');
                    this.resetTypeahead();
                    return;
                }
                this.user_list.push({
                    is_selected: true,
                    name: user.name,
                    id: user.id,
                    email: user.email
                })
                this.user_list = _.orderBy(this.user_list, ['is_selected', 'name'], ['desc', 'asc']);
                this.resetTypeahead();
            }
        },
        resetTypeahead(){
            this.$nextTick(()=>{
                this.query = '';
                let input = this.$refs['attendeesSearch'].$refs['input'];
                input.blur();
            })
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                users.push(`${user.name} | ${user.email}`)
            });
            this.users = users;
        },
        createUserList(attendee_list){
            let list = attendee_list.map( user => {
                return {
                    is_selected: true,
                    name: user.name,
                    id: user.id,
                    email: user.email
                }
            });
            this.user_list = _.orderBy(list, ['is_selected', 'name'], ['desc', 'asc']);
        },
        saveSelection(){
            let selected_users = this.user_list.filter( item => {
                return item.is_selected;
            });
            selected_users = selected_users.map(({id}) => Number(id) )
            
            if(selected_users.length == 0){
                this.$swal.fire('At least one attendee is required');
            }
            else{
                this.$emit('updatedSelectedUsers', {users: selected_users, send_invites: this.invite_attendees});
            }
            
        }
    },
    mounted(){
        //* get the send invites boolean from the webix table
        let current_row = window.webix.$$('meetingsTable').getItem(this.meeting_id);
        // set send_invites flag -- older rows have this value as undefined
        this.invite_attendees = current_row.send_invites != undefined ? current_row.send_invites : false;
        let meeting = this.meetings.find( item => {
            return item.id == this.meeting_id;
        })
        let attendees = meeting ? meeting.attendees : [];
        //set the enabled users
        this.createUserList(attendees);
        //* set typeahead list
        this.populateList();
    },
}
</script>

<style>

</style>