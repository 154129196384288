<template>
        <b-modal id="linkedActivityModal" hide-footer :title="modalTitle" style="height: 100%;">
            <b-alert :show="all_activities.length == 0">
                No avaliable activity plans.
            </b-alert>
            <div class="modal-title">
                Selected Activity: <span style="font-weight: bold;" class="text-primary">{{payload.name}}</span>
            </div>
            <b-overlay :show="is_loading">
                <webix-ui style="height: 100%;" :config="ui" v-model="all_activities" ></webix-ui>
            </b-overlay>
        </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { levelComputed, activityComputed } from '@/state/helpers'
import _ from 'lodash'
export default {
    props:{
        payload:{
            required: true,
        },
        activities:{
            default: [],
        },
    },
    data:() => ({
        is_loading: false,
        activity_plans_data: [],
        objective_data: [],
    }),
    computed:{
        ...levelComputed,
        ...activityComputed,
        ui(){
            let el = this;
            //eslint-disable-next-line
            let current_id = this.payload.level_id
            return {
                css: "webixModal",
                id: 'treeModal',
                view: 'tree',
                autoheight: true,
                template:"{common.icon()}#name#",
                minHeight: 500,
                maxHeight: 700,
                ready:function(){
                    setTimeout(()=>{
                        this.openAll();
                        /*this.addCss(el.payload.id, "invalid_selectable_row");
                        if(el.payload.parent_objective_id != null){
                            this.select(el.payload.parent_objective_id);
                        }*/
                    }, 50)
                },
                on:{
                    //eslint-disable-next-line
                    onItemClick(id){
                        
                        let selected_item = this.getItem(id);
                        if(selected_item.id == el.payload.id){
                            Swal.fire({
                                icon:'error',
                                title:"Invalid selection (can't set the parent to itself)",
                            })
                        }
                        else{
                            /*Swal.fire({
                                title: "Please note:",
                                text: "All sub-objectives under this Objective will have their parent objective reset. Please review all its sub-objective positions.",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#34c38f",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonText: "Yes",
                            }).then((result) => {
                                if (result.value) {
                                    el.$emit('selectedNewID', id);
                                }
                            });*/
                            el.$emit('selectedNewID', id);
                        }
                    },
                }
            }
        },
        all_activities(){
            //* return a flat array of all objectives
            return _.cloneDeep(this.activities)
            //return this.selected_objective_details.plan_data.plans;
        },
        modalTitle(){
            let title = `Update ${this.payload.modal_type} Hierarchy`;

            return title;
        },
    },
    methods:{
        isValidSelection(clicked_level, parent_level){
            if(this.payload.parent_id == null){
                return true;
            }
            if(clicked_level == parent_level){
                return true;
            }
            else if(clicked_level > parent_level && clicked_level - parent_level == 1){
                return true;
            }
            else{
                return false;
            }
        },
        getAvailableActivities(){
            /*alert(this.all_activities.length);
            let top_level_id = null;

            if(this.payload.parent_id == null){
                top_level_id = this.payload.level_id;
            }
            else{
                let index = _.findIndex(this.all_activities, {id:this.payload.parent_id});
                if(index != -1){
                    top_level_id = this.all_activities[index].level_id;
                }
            }

            if(top_level_id != null){
                //* only pass through the required objectives to the procrssTree method
                let available_activities = [];
                //* get all objectives that belong to the same level as the current item's parent level_id
                let parent_level_activities = _.filter(this.all_activities, {level_id: top_level_id});

                //*get all children of the top_level_id and find their linked objectives
                let peer_levels = _.filter(this.levels, {parent_id: top_level_id});
                let peer_activities = [];
                peer_levels.forEach( level => {
                    let peers = _.filter(this.all_activities, {level_id: level.id});
                    peer_activities = peer_activities.concat(peers);
                });

                available_activities = available_activities.concat(parent_level_activities, peer_activities);

                this.processTree(available_activities);


            }
            else{
                alert('Hmm....')
            }*/
            return this.all_activities
            

        },
        processTree(data_array){
            var data = _.cloneDeep(data_array);
            data.forEach((element) => {
                element["parent"] = element.parent_objective_id;
                element["text"] = element.name;

                console.log(`ID: ${element.id} -> Parent: ${element.parent_objective_id}`)
            });
            data.forEach((ele) => {
                let parent_objective_id = ele.parent_objective_id;
                if (parent_objective_id == null) {
                    //* Parent item -- do nothing
                } 
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_objective_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;

                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_objective_id});
                            if(index == -1){
                                d.parent_objective_id = null ;
                            }

                        }
                    });
                }
            });
            if(this.isParentLevel){
                //* Remove duplicate elements
                data = data.filter((ele) => ele.parent_objective_id == null);
            }
            else{
                //* Remove duplicate elements
                data = data.filter((ele) => ele.data != undefined || ele.parent_objective_id == null);
                //* fix for activity-other objectives not showing children as a tree
                data = data.filter( (ele) => ele.flagged == undefined );
            }
            console.log('Should be a tree', data)
            this.objective_data = data;
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid
        },
    },
    mounted(){
        this.getAvailableActivities();
    },
}
</script>
<style>
    .hierarchyModalAlert{
        padding: 0.4rem;
    }
    .hierarchyModalAlert .close{
            padding: 0;
            font-size: 20px;
        }
    .webixModal .webix_selected{
        /*background-color: #daecff !important;*/
        text-decoration: underline black ;
        color:dodgerblue;
        font-weight: bold;
    }
    .show_objective_modal{
        cursor: pointer;
        color: transparent;
    }
    .show_hierarchy_text:hover .show_objective_modal{
        color: dodgerblue;
    }
    /*.show_hierarchy_modal:hover{
        color: dodgerblue;
    }*/

    .selectable_hierarchy_row{
        background-color:rgb(220, 255, 243);
    }
    .webix_tree_item.invalid_selectable_row{
        cursor: not-allowed !important;
        color:gray
        /*background-color:rgb(255, 131, 131);*/
    }
</style>