<template>
    <div>
        <b-alert :show="can_show_activities == false && selected_meeting_objective_id != -1">
            The selected objective is not linked to the selected meeting. Therefore you cannot link Actions.
        </b-alert> 
        <b-card v-if="can_show_activities" class="card-hover">
            <div>
                <div class="">
                    <div class="">
                    <!-- flip front -->
                        <div style="text-align: left;">
                            <Table 
                            prevent_first_row_select
                            :active_id="selected_action_id"
                            :columns="columns"
                            :uid="table_uid"
                            :ref="table_uid"
                            :data="activity_array"
                            :table_mode="table_view_mode"
                            :treetable="true"
                            :is_loading="is_loading"
                            :context_menu_items="['View', 'Edit', 'Delete']"
                            only_editable_columns
                            :default_values="default_values"
                            :ignore_edit_only_rules="['parent_id', 'level_id', 'milestone_objective_id']"
                            :button_methods="button_methods"
                            re_order
                            @onRowDrag="onRowDrag"
                            @saveTable="saveTable"
                            @selectedRowEvent="selectedRowEvent"
                            @onDataUpdate="onChangesMade"
                            @updatedOrderEvent="onRowIndexUpdate"
                            @deleteRows="deleteRow"
                            :validation="table_validation_rules"
                            :required_permissions="['action-plans','action-plans-manager']"
                            >
                                <template #title>
                                    Action Plan
                                    &nbsp;&nbsp;>&nbsp;&nbsp;
                                    <span class="text-primary">{{ selectedObjectiveTitle }}</span>
                                </template>
                                <template #buttons>
                                    <span class="form-check form-switch pt-1 me-4 ms-2" style="font-size: 10px;">
                                        <span> <!-- :disabled="is_editing"-->
                                            <input  class="form-check-input" style="cursor:pointer;" v-model="only_show_linked_activities" type="checkbox" id="showLinkedActivities"/>
                                            <label class="form-check-label" for="showLinkedActivities">
                                                Only Show Linked Activities
                                            </label>
                                        </span>
                                    </span>

                                    <span class="me-2">
                                        <b-button size="sm" variant="primary" @click="showScheduleModal()">
                                            Configure Reminders For Meeting
                                        </b-button>
                                    </span>
                                    
                                    <b-button @click="showActivityLinkingModal()" :disabled="selected_meeting_objective_id == -1"  variant="primary" class="me-2" size="sm">
                                        <i class="fas fa-plus me-1"></i>
                                        Link Actions
                                    </b-button>
                                </template>
                            </Table>
                            <!--
                            <webix-ui :config="ui" v-model="activities" />
                            -->
                        </div>
                    <!-- flip back -->
                    </div>
                </div>
            </div>
        </b-card>
        <ActivityLinkingModal v-if="show_activity_linking_modal" :payload="modal_payload" :activities="activities" @selectedNewID="handleNewActivityParent"/>
        <HierarchyModal v-if="show_level_linking_modal" :payload="modal_payload"/>
        <ObjectiveModal v-if="show_objective_linking_modal" :payload="modal_payload"/>
        <ScheduleModal v-if="show_schedule_modal" :action_plan_id="selected_action_id"/>

        <ActivityTree @saved="handleSave()" :action_plan_id="selected_activity_plan_id" v-if="show_plan_linking_modal"/>
        <!--- rating test -->
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue'
import _ from 'lodash'
import { meetingState, meetingMethods, activityRatingMethods, activityMethods, activityComputed, levelComputed, levelMethods, adminComputed } from "@/state/helpers.js";
import { format, parseISO } from 'date-fns';

import ActivityLinkingModal from '@/components/objectives/components/misc/activityModals/linkedActivityModal.vue'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
//import HierarchyModal from '../objectives/components'
import ObjectiveModal from '@/components/objectives/components/misc/objectiveModals/linkedObjectiveModal.vue'

import ActivityTree from './modals/activityLinking.vue'
import ScheduleModal from './scheduleModal.vue';



export default {
    components:{
        Table,
        ActivityLinkingModal,
        ObjectiveModal,
        HierarchyModal,
        ActivityTree,
        ScheduleModal,

    },
    watch:{
        selected_meeting_objective_id(){
            this.isLinkedObjective();
        },
        meeting_activity_plans(){
            this.processActivities();
        },
        activity_array(){
            if(this.only_show_linked_activities == false){
                //* need to highlight the linked activity plans when showing the entire tree
                this.$nextTick(()=>{
                    this.highlightLinkedActivities()
                })
            }
            
        },
    },
    data:() => ({
        show_schedule_modal: false,
        show_activity_tree: true,
        selected_activity_plan_id: -1,
        //* table vars
        table_uid: 'activityList',
        table_view_mode: 'view',
        is_loading: false,
		delete_array: [],
        changes_made_flag: false,
        activities: [],
        can_show_activities: false,
        only_show_linked_activities: true,
        //* -------------

        isShow: {
            isCancel: false,
            isView: true,
            isEditingNew: false,
            isEditingExisting: false,
            isGuideline: false,
        },

        status_options:[
            { id: '0', value: '0%'},
            { id: '25', value: '25%'},
            { id: '50', value: '50%'},
            { id: '75', value: '75%'},
            { id: '100', value: '100%'}
        ],
        //* booleans to show modals
        show_activity_linking_modal: false,
        show_objective_linking_modal: false,
        show_level_linking_modal: false,
        show_plan_linking_modal: false,
        modal_payload: null,
        selected_action_id: -1,
    }),
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        ...meetingState,
        activity_array(){
            if(this.only_show_linked_activities == false){
                return this.activities;
            }
            else{
                return this.meeting_activity_plans.filter( item => item.linked_to_meeting == true );
            }
        },
        selectedObjectiveTitle(){
            if(this.selected_meeting_objective_id == -1){
                return 'No Meeting Selected'
            }
            else{
                let objective_name = '';
                let objective = this.meeting_objectives.find( item => {
                    return item.id == this.selected_meeting_objective_id;
                })
                if(objective){
                    objective_name = objective.name;
                }
                return objective_name;
            }
        },
        canShowRatingComponent() { return !!this.selected_user_id && this.selected_objective_details != null },
        activity_inbox(){
            //TODO VERIFY ACTIVITY_INBOX
            let data = [];
            //if(this.selected_objective_details != null){
            //    data = this.selected_objective_details.plan_data.inbox;
            //}
            return data;
        },
        available_activities(){
            let data = this.meeting_activity_plans.map( activity => {
                return {
                    id: activity.id,
                    value: activity.name,
                }
            });
            data.unshift({
                id: 'null',
                value: "Not Linked"
            });
            return data;
        },
        available_levels(){
            return this.levels.map( level => {
                return {
                    id: level.id,
                    value: level.name,
                }
            })
        },
        available_objectives(){
            return this.meeting_objectives.map( objective => {
                return {
                    id: objective.id,
                    value: objective.name,
                }
            })
        },
        
        availableLinkingActivities(){
            let options = [{ id: 'null', value: 'Not Linked'}];
            if(this.selected_objective_details != null){
                this.selected_objective_details.plan_data.forEach( plan => {
                    options.push({
                        id: plan.id,
                        value: plan.name
                    })
                })
            }
            return options;
        },
        activeUserId(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
                //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        returnCurrentUserName(){
            let name = '';
            if(this.activeUserId == -1){
                name = 'All users';
            }
            else{
                let index = _.findIndex(this.userList, {id: Number(this.activeUserId)})
                if(index != -1){
                    name = this.userList[index].value;
                }
                else{
                    name = '#error#';
                }
            }
            return name;
        },

        //* table props
        columns(){
            let el = this;
            return [
                { id: "progress", header: "Progress", width: '100'},
                //* NB! type is used by webix so just remember activity_type == type
                {
                    id: "name", header: ["Action", {content: "textFilter"}], fillspace: 2, editor: 'popup',
                    template: (obj, common, value) => {
                        if(value != ''){
                            return common.treetable(obj, common) + "<span>"+value+"</span>";
                        }
                        else{
                            return '';
                        }
                    },
                    exportAsTree: true,
                },
                { id: "activity_type", header: "Type", fillspace: 0.6, editor: 'combo', options:['Programme', 'Project', 'Task']},
                //{ id: "name", header: "Name", fillspace: 0.8, editor: 'text'},
                //{ id: "description", header: "Description", fillspace: 1, editor: 'text'},
                { id: "responsible_user_id", header: "Responsible User", editor: "combo", options: this.userList,
                    fillspace: el.table_view_mode === 'edit' ? 2 : 0.8,
                    suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
                },
                { id: "owner_user_name", header: "From", fillspace: 0.6},
                { id: "status", header: "Status", fillspace: 0.5, editor: 'combo', options: this.status_options},
                { id: "start_date", header: "Start Date", fillspace: 0.7, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "end_date", header: "End Date", fillspace: 0.7, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                /*{
                    id: 'parent_id', header: 'Linked Activity', editor: "combo", fillspace: 0.9,
                    options: this.availableLinkingActivities,
                },*/
                /*{
                    id: "milestone_objective_id", //* _name
                    header: 'Linked Objective',
                    fillspace: 1,
                    editor: 'combo',
                    //eslint-disable-next-line
                    options: el.getAvailableObjectives,
                },*/
                { 
                    id: 'parent_id',
                    header: 'Linked Activity',
                    fillspace: 0.8,
                    editor: "combo", options: el.available_activities,
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data:el.available_activities,
                            template:"#value#",
                            yCount:10
                        }
                    },
                },
                {
                    id: "level_id",
                    //editor: "combo",
                    header: "Structure",
                    fillspace: 0.8,
                    editor: "combo", options: this.available_levels,
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data:this.available_levels,
                            template:"#value#",
                            yCount:10
                        }
                    }
                },
                {
                    id: "milestone_objective_id",
                    header: 'Linked Objective',
                    fillspace: 1,
                    editor: "combo", options: this.available_objectives,
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data:this.available_objectives,
                            template:"#value#",
                            yCount:10
                        }
                    }
                },
                //{
                //    id: "reminders",
                //    header: 'Reminders',
                //    fillspace: 0.5,
                //    //eslint-disable-next-line
                //    template(obj){
                //        if(obj.is_empty || obj.is_new){
                //            return '';
                //        }
                //        return `
                //        <div class="d-flex" style="justify-content:flex-start;">
                //            <strong class="cursor-pointer edit_action bg-primary bg-soft text-center" style="width: 2rem;">Edit</strong>
                //        </div>`
                //    }
                //}
            ];
        },
        default_values(){
            let el = this;
            return [    
                { id : "level_id", value: el.selected_level.id },
                { id : "level_milestone_id", value: null },
                { id : "milestone_objective_id", value: this.selected_meeting_objective_id },
                { id : "status", value: '0'},
                { id : "parent_id", value: null },
                { id : "start_date", value: format(new Date(), 'yyyy-MM-dd')},
                //{ id : "deadline", value: format(new Date(), 'yyyy-MM-dd')}
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_activity_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showActivityModal(item);
                },
                //eslint-disable-next-line
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                //eslint-disable-next-line
                'show_objective_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                }
            }
            return onClick;
        },
        table_validation_rules(){
            return{
                "name":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "activity_type":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "responsible_user_id":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "status":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "start_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == {} || item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "end_date": function(item, obj){
                    if(item == {} || obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        },

        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid;
        },
        dropdownFix(){
            if(this.changes_made_flag || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },
        getAvailableObjectives(){
            let names = [
                {id: 'null', value: "Not Linked"}
            ];
            
            if(this.activity_data.parent_objectives != undefined){
                this.activity_data.parent_objectives.forEach( item =>{
                    names.push(item);
                })
            }
            this.activity_data.objectives.forEach( item =>{
                names.push(item);
            })
            
            return names;
        },
    },
    methods:{
        ...activityRatingMethods,
        ...activityMethods,
        ...levelMethods,
        ...meetingMethods,
        showScheduleModal(){
            this.selected_activity_plan_id = window.webix.$$(this.table_uid).getSelectedItem()
            this.show_schedule_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('scheduleModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "scheduleModal") {
                        this.show_schedule_modal = false;
                    }
                });
            })
        },
        showActivityLinkingModal(){
            this.show_plan_linking_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('activityLinkingModal')
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "activityLinkingModal") {
                        this.show_plan_linking_modal = false;
                    }
                });
            })
        },
        processActivities(){
            let data = [];
            let temp_data = _.cloneDeep(this.meeting_activity_plans);
            temp_data.forEach( item => {
                if(item.status != 'Sent'){
                    item.activity_type = item.type;
                    item.type = 'folder';
                    item.progress = `<div class="bar mt-1"><div class="bar-inner" style="width: ${item.status}%"></div></div>`;
                    let index = _.findIndex(this.activity_inbox, { objective_plan_id: item.id});
                    if(index != -1){
                        item.activity_status = this.activity_inbox[index].status;
                    }
                    else{
                        item.activity_status = 'error';
                    }
                    data.push(item);
                }
            })
            //data = _.orderBy(data, ['item_order']);
            data = this.processTree(data);
            this.activities = data;
            setTimeout(()=>{
                this.highlightLinkedActivities();
            }, 500)
            
        },
        processTree(data_array){
            var data = _.cloneDeep(data_array);
            data.forEach((ele) => {
                delete ele.children;
                let parent_id = ele.parent_id;
                if (parent_id == 0) {
                    ele.parent_id = null;
                    //* Parent item -- do nothing
                    //filter_array.push(parent_id)
                } 
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;
                            
                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }
                        //d.data = undefined
                        }
                    });
                }
            });
            if(this.isParentLevel){
                //* Remove duplicate elements
                data = data.filter((ele) => ele.parent_id == null);
            }
            else{
                //* Remove duplicate elements
                data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
                //* fix for activity-other objectives not showing children as a tree
                data = data.filter( (ele) => ele.flagged == undefined );
            }
            return data;
        },
        //* table methods
        selectedRowEvent(row){
            if(!row){
                this.selected_action_id = -1;
                return;
            }
            //* Event returns the selected row
            let index = _.findIndex(this.activities, {id: row.id})
            if(index != -1){
                this.selected_action_id = this.activities[index].id;
            }
            
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },

        onRowDrag(drop_data){
            let id = drop_data.id;
            let parent_id = drop_data.parent_id;
            let index = _.findIndex(this.selected_objective_details.plan_data, {id:id});
            if(index != -1){
                this.is_loading = true;
                let row = this.selected_objective_details.plan_data[index];
                let new_param = {
                    id: row.id,
                    parent_id: parent_id,
                    level_id: row.level_id,
                    name: row.name,
                    start_date: this.getFormattedDate(row.start_date),
                    end_date: this.getFormattedDate(row.end_date),
                    type: row.activity_type != undefined ? row.activity_type : row.type,
                    description: 'Empty',
                    body: 'Empty',
                    status: row.status,
                    owner_id: row.owner_user_id,
                    responsible_user_id: Number(row.responsible_user_id),// row.owner_user_id//this.findLevelUserId(row.owner_name)?.id,
                }

                let objective_id = this.selected_objective.id;
                let activity_params = {
                    level_id: this.selected_level.id,
                    objective_id: objective_id,
                    plans: [new_param],
                }
                this.is_loading = true;
                this.saveActivityInbox(activity_params)
                .then(()=>{
                    let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.delete_array = [];
                        this.changes_made_flag = false;
                        //this.toggleViewMode();
                        this.is_loading = false;
                        let objective = _.find(this.activity_data.objectives, {id: objective_id})
                        this.setSelectedObjective(null);
                        this.$nextTick(()=>{
                            this.setSelectedObjective(objective);
                        })
                    })
                })
            }
        },
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        saveTable(data){
            let rows_to_save = [];
            data.forEach( row => {
                let param = {
                    "id": row.is_new ? -1 : row.id,
                    "parent_id": row.parent_id,
                    "owner_id": row.responsible_user_id,
                    "level_id": row.level_id,
                    "responsible_user_id": row.responsible_user_id,
                    "objective_id": row.milestone_objective_id ? row.milestone_objective_id : this.selected_meeting_objective_id,
                    "name": row.name,
                    "type": row.activity_type,
                    "description": "N/a",
                    "body": "N/a",
                    "status": row.status,
                    "start_date": this.getFormattedDate(row.start_date),
                    "end_date": this.getFormattedDate(row.start_date),
                    "meeting_id": this.selected_meeting_id,
                }
                if(param.id == param.parent_id){
                    param.parent_id = 0;
                }
                if(param.parent_id == null || param.parent_id == 'null'){
                    param.parent_id = 0;
                }
                rows_to_save.push(param);
            })
            let save_payload = {
                plans: rows_to_save,
            }
            this.saveMeetingActivity(save_payload)
            .then(()=>{
                this.getMeetingActions()
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                })
            })
        },
        getMeetingActions(){
            return new Promise( resolve => {
                let params = {
                    id: this.selected_meeting_id,
                    objective_id: this.selected_meeting_objective_id
                };
                this.loadMeetingActivities(params)
                .then(()=>{
                    resolve();
                })
            })
        },

        saveTableOld(){
            return new Promise(( resolve, reject) => {
                // eslint-disable-next-line
                let table = $$(this.table_uid);
                let is_valid = table.validate();

                if(is_valid == false){
                    reject();
                    Swal.fire("Invalid rows found.", "", "warning")
                }

                if(is_valid){
                    const table_data = table.serialize(null, true);
                    //flatten the nested array
                    const flatten = (members) => {
                        let data = [];
                        return members.map(m => {
                            if (m.data && m.data.length) {
                                data = [...data, ...m.data];
                            }
                            return m;
                        }).concat(data.length ? flatten(data) : data);
                    };
                    let all_data = flatten(table_data);



                    let rows_to_save = [];
                    all_data.forEach( row => {
                        if(!row.is_empty){
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? -1 : row.id,
                                parent_id: row.parent_id != undefined ? row.parent_id : null,
                                level_id:  this.selected_level.id,
                                objective_id: this.selected_objective.id,
                                name: row.name,
                                start_date: this.getFormattedDate(row.start_date),
                                end_date: this.getFormattedDate(row.end_date),
                                type: row.activity_type,
                                description: 'Empty',
                                body: 'Empty',
                                status: row.status,
                                owner_id: row.owner_id != undefined ? row.owner_id : this.core.id,
                                responsible_user_id: Number(row.responsible_user_id),// row.owner_user_id//this.findLevelUserId(row.owner_name)?.id,
                            };
                            rows_to_save.push(params);
                        }
                    });
                    //let objective_id = this.selected_objective.id;
                    let activity_params = {
                        //level_id: this.selected_level.id,
                        //objective_id: objective_id,
                        plans: rows_to_save
                    }
                    this.is_loading = true;
                    this.saveActivityInbox(activity_params)
                    .then(()=>{
                        let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                        this.loadObjectiveDetailAPI(params)
                        .then(()=>{
                            this.delete_array = [];
                            this.changes_made_flag = false;
                            this.toggleViewMode();
                            this.is_loading = false;
                        })
                    })
                }
            })
        },
        getFormattedDate(value){
            let newValue = ''
            if(typeof value === 'string'){
                newValue = format(parseISO(value), 'dd-MMM-yyy');
            }
            else{
                newValue = format(value, 'dd-MMM-yyy');
            }
            return newValue;
        },
        onRowIndexUpdate(sortData){
            this.is_loading = true;
            let params = {
                type: 'activity',
                data: sortData,
            }
            this.updateItemOrder(params)
            .then(()=>{
                let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.is_loading = false;
                })
            })
        },
        deleteRow(data){
            let promises = [];
            data.forEach( item => {
                promises.push(this.deleteObjectiveTask({key_action_id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.getMeetingActions()
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                })
			})
        },
        showObjectiveModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Activity'
                this.show_objective_linking_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkedActivityModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedActivityModal") {
                            this.show_objective_linking_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        showActivityModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Activity'
                this.show_activity_linking_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkedActivityModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedActivityModal") {
                            this.show_activity_linking_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        showLevelModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Activity'
                this.show_activity_linking_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkedActivityModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedActivityModal") {
                            this.show_activity_linking_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        //* -------------


        selectActivity(item){
            this.selected_
            this.setSelectedActivity(item);
        },
        saveRow(){
            //eslint-disable-next-line
            let table = $$("activityList");
            let row = table.getItem(-1);
            let params = {
                id: -1,
                parent_id: 0,
                owner_id: 1,
                responsible_user_id: 1,
                level_id: row.level_id != undefined ? row.level_id : this.selected_level_id, 
                objective_id: this.selected_objective.id,
                name: row.name,
                type: row.type,
                description: row.description,
                body: row.body,
                status: row.status,
                start_date: row.start_date,
                end_date: row.end_date,
            }
            this.saveActivityInbox(params)
            .then(()=>{
                let params = {
                    id: this.selected_objective.id,
                    user_id: this.activeUserId,
                }
                this.loadObjectiveDetailAPI(params);
            })
        },


        //* webix misc
        initColumnConfig(){
            // eslint-disable-next-line
            /*let table = $$("activityList");
            if (!this.ownerDropdown || !table) {
                setTimeout(() => {
                    this.initColumnConfig();
                }, 100);
                return;
            }

            table.getColumnConfig("owner_name").suggest = this.ownerDropdown.map((item) => {
                return {
                    id: item,
                    value: item,
                };
            });*/
        },

        /* modal methods */
        showHierarchyModal(payload){
            if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }
            else{
                if(this.table_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = 'Objective'
                    this.show_level_linking_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('hierarchyModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                                this.show_level_linking_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            }
        },
        handleSave(){
            this.getMeetingActions();
            this.show_plan_linking_modal = false;
        },
        handleNewActivityParent(newID){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            //* update the parent id fields (the dollar property mignt be unused)
            item['parent_id'] = Number(newID);
            item['$parent'] = Number(newID);

            let table_state = table.getState(); //* get open nodes :)
            this.$bvModal.hide('linkedActivityModal') //* hide modal
            this.saveTable()
            .then(()=>{
                this.$nextTick(()=>{
                    table = window.webix.$$(this.table_uid);
                    table_state.open.forEach( id => {
                        if(table.exists(id)){
                            table.open(id);
                        }
                    })
                    if(item.parent_id != null && table.exists(item.parent_id)){
                        table.open(item.parent_id);
                    }
                    table.select(item.id);
                    let index = _.findIndex(this.all_activities, { id: item.id });
                    if(index != -1){
                        let activity = _.cloneDeep(this.all_activities[index]);
                        this.$eventHub.$emit('selectedObjective', activity);
                        this.setSelectedActivity(activity);
                    }
                })
                //this.loadObjectiveDetailAPI(this.selected_objective.id);
            })
        },
        saveTest(){
            let p = {
                plans:[
                    {
                        "id": -1,
                        "parent_id": 0,
                        "owner_id": 38,
                        "level_id": 54,
                        "responsible_user_id": 38,
                        "objective_id": 158,
                        "name": "This is a test action",
                        "type": "task",
                        "description": "Test action with meeting id",
                        "body": "Test action with meeting id",
                        "status": "20",
                        "start_date": "2021-05-05",
                        "end_date": "2021-06-06",
                        "meeting_id": 1
                    }
                ]
            }
            this.saveMeetingActivity(p);
        },
        isLinkedObjective(){
            let meeting = this.meetings.find( m => {
                return m.id == this.selected_meeting_id
            });
            if(meeting){
                let has_objective = meeting.objectives.findIndex( objective => {
                    return objective.id == this.selected_meeting_objective_id
                })
                if(has_objective != -1){
                    this.can_show_activities = true;
                    return;
                }

            }
            this.can_show_activities = false;
        },
        highlightLinkedActivities(){
            if(this.only_show_linked_activities == true){
                return;
            }
            let table = window.webix.$$(this.table_uid);
            if(table){
                table.clearCss("marker");
                table.closeAll();
                table.refresh();
                this.meeting_activity_plans.forEach( item => {
                    if(item.linked_to_meeting){
                        if(table.exists(item.id)){
                            let current_item = table.getItem(item.id);
                            while(current_item.$parent != 0){
                                table.open(current_item.$parent);
                                current_item = table.getItem(current_item.$parent);
                            }
                            table.addCss(item.id, "marker", true);
                        }
                    }
                })
                table.refresh();
            }
        },
    },
    mounted(){
        this.isLinkedObjective();
        //this.$bvModal.show("scheduleModal");
    },
}
</script>

<style lang="scss" scoped>

::v-deep .bar {
    width: 100%;
    height: 10px;
    border-radius: 8px;
    background: #e9ecef;
    overflow: hidden;

    &-inner {
      // width: attr(data-percent);
        height: 100%;
        background: dodgerblue;
    }
}
</style>