<template>
    <b-card>
        <!--
            :required_permissions="['objectives', 'objectives-manager']"
            enforce_structure_checks
            :edit_permissions="{manager:'objectives-manager', user:'objectives'}"
            -->
        <Table
            :columns="columns"
            :uid="table_uid"
            :ref="table_uid"
            :data="processed_meeting_objectives"
            :treetable="true"
            re_order
            no_empty_rows
            hide_action_buttons
            :is_loading="is_loading || is_loading_objectives"
            :context_menu_items="['View', 'Edit', 'Delete']"
            only_editable_columns
            :ignore_edit_only_rules="['level_id', 'linked_objective_id', 'level_milestone_id']"
            :active_id="this.selected_meeting_objective_id"
            :validation="table_validation_rules"
            :default_values="default_values"
            prevent_first_row_select
            @selectedRowEvent="selectedRowEvent"
            @updateViewMode="updateViewMode"
            @saveTable="saveTable"
            :button_methods="button_methods"
            @updatedOrderEvent="onRowIndexUpdate"
            @deleteRows="deleteRow"
            
        >
            <template #title>
                Objectives
                <span class="text-muted" v-show="only_show_linked_objectives == false">
                    (showing objectives for the selected level)
                </span>
                &nbsp;&nbsp;>&nbsp;&nbsp;
                <span class="text-success">{{ selectedMeetingTitle }}</span>
                <span class="ms-1 text-muted" v-if="objectives_found_in_level_count > 0 && meeting_data">
                    ({{objectives_found_in_level_count}}/{{meeting_data.objectives.length}} objectives are unavailable on the current level.)
                </span>
                <!--
                &nbsp;&nbsp;>&nbsp;&nbsp;
                <span class="text-success">{{ mileStoneName }}</span> -->
            </template>
            <template #buttons>
                <span class="form-check form-switch pt-1 me-3 ms-2" style="font-size: 10px;">
                    <span> <!-- :disabled="is_editing"-->
                        <input  class="form-check-input" style="cursor:pointer;" v-model="only_show_linked_objectives" type="checkbox" id="showLinkedObjectives"/>
                        <label class="form-check-label" for="showLinkedObjectives">
                            Only Show Linked Objectives
                        </label>
                    </span>
                </span>
                <b-button @click="showObjectivesLinkingModal()" v-show="hideButton"  variant="primary" size="sm">
                    <font-awesome-icon icon="fa-plus" class="me-1 fa-lg"/>
                    Link Objectives
                </b-button>
                
            </template>
        </Table>
        <!--<b-alert class="mb-0 mt-2" :show="objectives_found_in_level_count > 0">
            <span v-show="objectives_found_in_level_count == 1">
                One objective is not found 
            </span>
            <span v-show="objectives_found_in_level_count > 1"></span>
            One or more objectives are not found under the selected level.
        </b-alert>-->
    </b-card>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import {
    meetingMethods, meetingState,
    levelMethods, levelComputed,
    adminComputed, activityComputed,
} from '@/state/helpers'
import TableHelpers from '@/mixins/tableHelpers.js';
import {format} from 'date-fns';
import _ from "lodash";
export default {
    components:{
        Table
    },
    mixins:[ TableHelpers ],
    data:() => ({
        table_view_mode: 'view',    
        is_loading: false,
        delete_array:[],
        changes_made_flag: false,
        table_uid: 'objectiveMeetingsTable',
        active_id: -1,
        tree_data:[],

        only_show_linked_objectives: true,

        objectives_found_in_level_count: 0,
    }),
    watch:{
        processed_meeting_objectives(){
            if(this.meeting_data != null){
                this.$nextTick(()=>{
                    this.highlightLinkedObjectives(this.meeting_data.objectives)
                })
            }
        },
        selected_meeting_id(){
            let meeting = this.meetings.find( meet => {
                return meet.id == this.selected_meeting_id
            });
            if(meeting){
                //this.highlightLinkedObjectives(meeting.objectives)
            }
            else{
                this.clearHighlightedLinkedObjectives();
            }
        }
        
    },
    computed:{
        ...meetingState,
        ...levelComputed,
        ...adminComputed,
        ...activityComputed,
        hideButton(){
            return this.selected_meeting_id != -1 ? true : false;
        },
        selectedMeetingTitle(){
            let title = 'No Meeting Selected'
            if(this.selected_meeting_id == -1){
                return title;
            }
            else{
                let meeting = this.meetings.find( item => {
                    return item.id == this.selected_meeting_id;
                })
                if(meeting){
                    title = meeting.title;
                }
                return title;
            }
        },
        processed_meeting_objectives(){
            if(this.only_show_linked_objectives){
                //let data = _.cloneDeep(this.meeting_objectives);
                if(this.meeting_data == null){
                    return [];
                }
                else{
                    let data = _.cloneDeep(this.meeting_data.objectives);
                    data.forEach( item =>{
                        if(item.level_milestone_id != null){
                            let milestone = this.company_milestones.find( m => m.id == item.level_milestone_id);
                            item['parent_milestone_name'] = milestone ? milestone.name : 'Milestone missing'
                        }
                        if(item.parent_objective_id != null){
                            let objective = this.meeting_objectives.find( o => o.id == item.parent_objective_id);
                            item['parent_objective_name'] = objective ? objective.name : 'Objective missing'
                        }
                    })
                    return data;
                }
            }
            var data = _.cloneDeep(this.meeting_objectives);
            //* order all items by their item order
            //data = _.orderBy(data, ['item_order']);
            data.forEach((element) => {
                element["parent_id"] = element.parent_objective_id;
                element["parent"] = element.parent_id;
                element["text"] = element.name;
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id == null) {
                    //* Parent item -- do nothing
                } 
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;

                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }

                        }
                    });
                }
            });

            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            return data;
        },
        columns(){
            var el = this;
            let columns = [
                /*{
                    id: "progress", tooltip: '#progress_value#%', header: "Progress",
                    width: '100', compact: true,
                    template: (obj) => {
                        if(obj.progress_value != undefined){
                            return `<div class="bar mt-1"><div class="bar-inner" style="width: ${obj.progress_value}%"></div></div>`
                        }
                        else{
                            return '';
                        }
                    },
                },*/
                {
                    id: "name", editor: "popup", header: "Objective", fillspace: 1.8, sort: "text", exportAsTree: true,
                    compact: true, tooltip:(obj)=>{
                        if(obj.is_empty){ return '' }
                        else{
                            return obj.name;
                        }
                    }, css: { "text-align": "left", "word-wrap": "break-word" },
                    template: (obj, common, value) => {
                        if(value != ''){
                            return common.treetable(obj, common) + value;
                        }
                        else{
                            return '';
                        }
                    },
                },
                {
                    id: "level_id", header: "Structure", fillspace: 0.8,
                    template:function(obj){
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            if(el.table_view_mode === 'edit'){
                                //* we only need to show the pencil icon in edit mode
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            }
                            else{
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            return ''
                        }
                    },
                },
                {
                    id: "level_milestone_id",
                    header: 'Linked Milestone',
                    fillspace: 1,
                    /*editor: 'combo',
                    options: el.getAvailableMilestones(),*/
                    template:function(obj){
                        if(obj.is_new){
                            if(obj.parent_milestone_name == undefined){
                                if(obj.level_milestone_id == null){
                                    return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>Not Linked</span>`
                                }
                                else{
                                    return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>${obj.parent_objective_id}</span>`
                                }
                            }
                            else{
                                if(obj.parent_objective_id == null){
                                    return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>${obj.parent_milestone_name}</span>`
                                }
                                else{
                                    return `<span class="show_hierarchy_text"> -- </span>`
                                }

                            }
                        }
                        if(obj.is_empty == undefined){
                            let name = obj.parent_milestone_name;
                            if(el.table_view_mode === 'edit'){
                                // we only need to show the pencil icon in edit mode
                                return `<span class="show_hierarchy_text"><i class="me-1 show_milestone_modal fas fa-pen"></i>${name}</span>`
                            }
                            else{
                                return `<span>${name}</span>`
                            }
                        }
                        else{
                            return '';
                        }
                    }
                },
                {
                    id: "linked_objective_id",
                    header: 'Linked Objective',
                    fillspace: 1,
                    template:function(obj){
                        if(obj.is_empty){
                            return '';
                        }
                        else if(obj.is_new){
                            let index = _.findIndex(el.getAvailableObjectives, {id: Number(obj.parent_objective_id)})
                            let name = 'Not Linked'; 
                            if(index != -1){
                                    name = el.getAvailableObjectives[index].name;
                            }
                            if(el.table_view_mode === 'edit'){
                                return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_objective_modal fas fa-pen"></i>
                                        ${name}
                                    </span>`
                            }
                        }
                        else{
                            if(obj.parent_objective_id){
                                //let index = _.findIndex(el.getAvailableObjectives, {id: Number(obj.parent_objective_id)})
                                let name = obj.parent_objective_name; 
                                //if(index != -1){
                                    //name = el.getAvailableObjectives[index].name;
                                //}
                                if(el.table_view_mode === 'edit'){
                                    return `
                                        <span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            ${name} 
                                        </span>`
                                }
                                else{
                                    return `
                                        <span>
                                            ${name}
                                        </span>`
                                }
                            }
                            else if(obj.parent_objective_id === null){
                                if(el.table_view_mode === 'edit'){
                                    return `
                                        <span class="show_hierarchy_text">
                                            <i class="me-1 show_objective_modal fas fa-pen"></i>
                                            Not Linked
                                        </span>`
                                }
                                else{
                                    return `
                                        <span>
                                            Not Linkable
                                        </span>`
                                }
                            }
                        }
                        
                    }
                },
                {
                    id: 'linked_to',
                    header: 'Linked To',
                    //hidden: this.only_show_linked_objectives,
                    fillspace: 1,
                    template(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        else{
                            if(obj.parent_objective_id == null){
                                if(obj.level_milestone_id == null){
                                    return `<span class="text-danger">Not Linked</span>`
                                }
                                return `<span>
                                    <b-badge variant="primary" style="border-radius: 0.2rem; font-weight: bold;" class="bg-primary bg-soft text-primary">&nbsp;M </b-badge>&nbsp;
                                    ${obj.parent_milestone_name}
                                </span>`
                                //<b-badge variant="primary" class="bg-primary">{{activity.type}}</b-badge>
                                //return obj.parent_milestone_name;
                            }
                            else{
                                return `<span>
                                    <b-badge variant="info" style="border-radius: 0.2rem; font-weight: bold;" class="bg-primary bg-soft text-dark">&nbsp;O </b-badge>&nbsp;
                                    ${obj.parent_objective_name}
                                </span>`
                                //return obj.parent_objective_name;
                            }
                        }
                    }
                },
                {
                    id: "start_date",
                    visibility:['edit'],
                    editor: "date",
                    header: "Start Date",
                    fillspace: 0.8,
                        format:function(value){
                            return el.parseDateFormat(value);
                        },
                    css: { "text-align": "left", "word-wrap": "break-word" },
                },
                {
                    id: 'deadline',
                    visibility:['edit',],
                    editor: "date",
                    header: "End Date",
                    fillspace: 0.8,
                    format:function(value){
                        return el.parseDateFormat(value);
                    },
                css: { "text-align": "left", "word-wrap": "break-word" },
                },
                {
                    id: 'date_range', header: 'Start - End', visibility:['view', 'delete'], fillspace: 0.8, css: { "text-align": "left", "word-wrap": "break-word" },
                    template(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        let start = el.parseDateFormat(obj.start_date);
                        let end = el.parseDateFormat(obj.deadline);

                        if(start === ''){ start = 'Na'}
                        if(end === ''){ end = 'Na'}
                        return `${start} - ${end}`;
                    }
                },
                {
                id: "owner_user_id",
                editor: "combo",
                header: "Owner",
                fillspace: el.table_view_mode === 'edit' ? 2 : 0.8,
                options: this.userList,
                suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
                },
            ];
            //* hide the level_milestone column if the user isn't on the parent level
            /*if(!el.isParentLevel){
                let index = _.findIndex(columns, {id: 'level_milestone_id'});
                columns.splice(index, 1);
                if(el.table_view_mode === 'view'){
                    index = _.findIndex(columns, {id: 'linked_objective_id'});
                    columns.splice(index, 1);
                }
            }*/
            if(el.table_view_mode === 'view'){
                let index = _.findIndex(columns, {id: 'level_milestone_id'});
                columns.splice(index, 1);

                index = _.findIndex(columns, {id: 'linked_objective_id'});
                columns.splice(index, 1);
            }
            return columns;
        },
        default_values(){
            let el = this;
            return [
                { id : "level_id", value: el.selected_level.id },
                //{ id : "parent", value: null },
                //{ id : "parent_id", value: null },
                { id : "level_milestone_id", value: null },
                { id : "parent_objective_id", value: null },
                { id : "start_date", value: format(new Date(), 'yyyy-MM-dd')},
                { id : "deadline", value: format(new Date(), 'yyyy-MM-dd')}
            ]
        },
        table_validation_rules(){
            //eslint-disable-next-line
            let el = this;
            return {
                "name": function(item, obj){
                    return el.isRequiredValidation(item, obj.is_empty);
                },
                "level_id": function(item, obj){
                    return el.isRequiredValidation(item, obj.is_empty);
                },
                "owner_user_id": function(item, obj){
                    return el.isRequiredValidation(item, obj.is_empty);
                },
                //eslint-disable-next-line
                "parent_objective_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        //* need to check is linked level is a parent level
                        let index = _.findIndex(el.levels, {id: obj.level_id});
                        if(index != -1){
                            if(el.levels[index].parent_objective_id == null){
                                //* is parent level
                                return true;
                            }
                            else{
                                //* not parent
                                if(item === '' || item == undefined || item == null){
                                    return false;
                                }
                                else{
                                    return true;
                                }
                            }
                        }
                        else { return false; }
                    }
                }
            };
        },
        button_methods(){
            return {};
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
    },
    methods:{
        ...meetingMethods,
        ...levelMethods,
        getMeetingActions(){
            return new Promise( resolve => {
                let params = {
                    id: this.selected_meeting_id,
                    objective_id: this.selected_meeting_objective_id
                };
                this.loadMeetingActivities(params)
                .then(()=>{
                    resolve();
                })
            })
        },
        //table methods
        //eslint-disable-next-line
        selectedRowEvent(row){
            if(!row){
                this.setSelectedMeetingObjectiveID(-1);
                return;
            }
            this.setSelectedMeetingObjectiveID(row.id);
            this.getMeetingActions();
        },
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        saveTable(data){
            console.log('SAVE DATA', data);
        },
        onRowIndexUpdate(sortData){
            /*this.is_loading = true;
            this.updateObjectiveRowOrder(sortData)
            .then(()=>{
                this.loadActivityData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                })
            })*/
            console.log(sortData);
        },
        // eslint-disable-next-line
        handleRowDelete(id, msg){
            return new Promise( (resolve) =>  {
                const index = _.findIndex(this.objective_data, {id: Number(id)})
                const name = this.objective_data[index].name;
                this.$swal.fire({
                    title: name,
                    text: msg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                })
                .then( result =>{
                    if(result.value){
                        this.forceDeleteObjective([id])
                        .then(()=>{
                            resolve('Said yes');
                        })
                    }
                    else{
                        resolve()
                    }
                })
            })
        },
        deleteRow(data){
            let params = [];

            data.forEach( item => {
                params.push(item.id);
            })
            this.is_loading = true;
            this.deleteObjective(params)
            .then( async (response)=>{
                //* here we loop through the responses to find any id's that have linked children
                let msg = response.data.message;
                const keys = Object.keys(msg);
                for(let i = 0; i< keys.length; i++){
                    let message_text = '';
                    let key = keys[i];
                    switch(msg[key]){
                        case 'has_children':{
                            message_text = 'This objective has linked children Objectives';
                            break;
                        }
                        case 'has_activities':{
                            message_text = 'This objective has linked Activity Plans';
                            break;
                        }
                        case 'has_key_results':{
                            message_text = 'This objective has linked Key Results';
                            break;
                        }
                        case 'has_operational_metrics':{
                            message_text = 'This objective has linked Operational Metrics';
                            break;
                        }
                    }
                    if(msg[key] !== 'success'){
                        await this.handleRowDelete(key, message_text);
                    }
                }
                this.loadActivityData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    //this.delete_array = [];
                    this.changes_made_flag = false;
                    this.$refs[this.table_uid].table_mode = 'view'; 
                    this.is_loading = false;
                })
            })
        },
        showObjectivesLinkingModal(){
            this.$eventHub.$emit('showObjectivesModal');
        },
        processTree(data_array){
            if(this.only_show_linked_objectives){
                return new Promise( resolve => {
                    resolve(_.cloneDeep(data_array))
                })
            }
            else{
                return new Promise( resolve => {
                    var data = _.cloneDeep(data_array);
                    //console.table(data)
                    //* order all items by their item order
                    //data = _.orderBy(data, ['item_order']);
                    data.forEach((element) => {
                        element["parent_id"] = element.parent_objective_id;
                        element["parent"] = element.parent_id;
                        element["text"] = element.name;
                    });
                    data.forEach((ele) => {
                        let parent_id = ele.parent_id;
                        if (parent_id == null) {
                            //* Parent item -- do nothing
                        } 
                        else {
                            //* If element is a child element, push to children (data) array.
                            data.forEach((d) => {
                                if (d.id === parent_id) {
                                    //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                                    ele['flagged'] = true;

                                    let childArray = d.data;
                                    if (!childArray) {
                                        childArray = [];
                                    }
                                    childArray.push(ele);
                                    d.data = childArray;
                                }
                                else{
                                    //* hmm
                                    //TODO - verify this fixed duplicates not being removed
                                    let index = _.findIndex(data, {id: d.parent_id});
                                    if(index == -1){
                                        d.parent_id = null ;
                                    }
                                }
                            });
                        }
                    });
                        //* Remove duplicate elements
                        data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
                        //* fix for activity-other objectives not showing children as a tree
                        data = data.filter( (ele) => ele.flagged == undefined );
                    //*Set parent root as expanded;
                    /*if(this.data.length > 0){
                        data[0]["opened"] = true;
                        data[0]["open"] = true;
                    }*/
                    resolve(data); 
                })
            }
        },
        clearHighlightedLinkedObjectives(){
            this.objectives_found_in_level_count = 0;
            let table = window.webix.$$(this.table_uid);
            if(table){
                table.clearCss("marker");
                table.closeAll();
                table.refresh();
            }
        },
        highlightLinkedObjectives(meeting_objectives){
            if(this.only_show_linked_objectives){
                return;
            }
            this.$nextTick(()=>{
                let table = window.webix.$$(this.table_uid);
                if(table){
                    table.clearCss("marker");
                    table.closeAll();
                    table.refresh();
                    let id_array = meeting_objectives.map( objective => { return objective.id });
                    let found = table.find(function(obj){
                        return id_array.includes(obj.id);
                    });
                    found.forEach( item => {
                        let current_item = table.getItem(item.id);
                        while(current_item.$parent != 0){
                            table.open(current_item.$parent);
                            current_item = table.getItem(current_item.$parent);
                        }
                        //table.open(item.id, true)
                        table.addCss(item.id, "marker", true);
                    })
                    this.objectives_found_in_level_count = meeting_objectives.length - found.length;
                    table.refresh();
                }
            })
        },
        handleForceHighlightEvent(){
            if(this.meeting_data){
                this.highlightLinkedObjectives(this.meeting_data.objectives)
            }
        }
    },
    mounted(){
        this.$eventHub.$on('forceObjectiveHighlight', this.handleForceHighlightEvent)
        //setTimeout(()=>{
        //    let found = window.webix.$$(this.table_uid).find(function(obj){
        //        return [107, 96, 108].includes(obj.id);
        //    });
        //    found.forEach( item => {
        //        window.webix.$$(this.table_uid).open(item.id)
        //        window.webix.$$(this.table_uid).addCss(item.id, "marker", true);
        //    })
        //    window.webix.$$(this.table_uid).refresh();
        //},5000)
        //this.tree_data = this.processTree(this.meeting_objectives);
    },
    beforeDestroy(){
        this.$eventHub.$off('forceObjectiveHighlight');
    }
}
</script>

<style>
.webix_cell.marker{
    background:rgba(34, 144, 239, 0.17) !important;
}
</style>